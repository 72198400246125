import React, { Suspense, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHireNowData, increment } from "../../redux/actions/sessionActions";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import Tech from "../../Components/HireNow/Tech";
import Requirement from "../../Components/HireNow/Requirements";
import Calender from "../../Components/HireNow/Calender";
import { Link } from "react-router-dom";

export default function Hirenow() {
  const dispatch = useDispatch();
  const [textInput, setTextInput] = useState("");
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const count = useSelector((state) => state.sessionHandler.count);

  const handleChange = (event) => {
    let value = event.target.value;
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      setError("Please enter a valid email address");
    } else {
      setError("");
    }
    setTextInput(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (textInput === "") {
      setError("Please enter your email");
    }
    if (error === "") {
      dispatch(increment());
      dispatch(setHireNowData(textInput));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Next-Gen AI Solutions</title>
        <meta
          name="description"
          content="Transform your business with our next-gen AI solutions. With years of expertise in generative AI and advanced machine learning, we deliver deeply rooted, cutting-edge technologies that optimize processes. Trust in our longstanding leadership in the AI industry."
        />
      </Helmet>
      <div style={{ paddingTop: "140px" }} className="container-hire">
      {
         count === 0 ? (
         <>
             <div className="col-md-6 mt-0">
              <h4 className="text-l1">{t("hirenow.forcompanies")}</h4>
              <h1 className="text-l2">
                {t("hirenow.connectwithus")}
              </h1>
              <p className="text-l3">
                {t("hirenow.hireorgethired")}
              </p>
      
              <div className="input-email">
                <input
                  className="email"
                  type="email"
                  value={textInput}
                  placeholder={t("hirenow.writeemail")}
                  onChange={(e) => handleChange(e)}
                  onKeyDown={handleKeyDown}
                  required
                />
                <div id="hirenow_email_error_mobile" className="invalid-feedback">{error}</div>
                <button
                  type="submit"
                  className="btn btn-hire"
                  onClick={handleSubmit}
                >
                  {t("hirenow.continue")}
                </button>
              </div>
              <div id="hirenow_email_error_desktop" className="invalid-feedback">{error}</div>
      
              <p className="text-look">
                {t("hirenow.lookingforjob")}{" "}
                <Link
                  className="link-job"
                  to="/careers"
                  onClick={() =>
                    ReactGA.event({
                      category: "Apply for Jobs",
                      action: "Click",
                      label: "Apply for Jobs",
                      value: 1,
                    })
                  }
                >
                  {t("home.apply")}
                </Link>
              </p>
            </div>
            <div className="col-md-6 mt-0 main-img2">
              <img
                fetchpriority="high"
                src="images/frame.svg"
                className="hirenow-image"
                alt="img"
              />
            </div>
         </>
        
        
        ) : count === 1 ? (
          <Tech />
        ) : count === 2 ? (
          <Requirement />
        ) : count === 3 ? (
          <Calender />
        ) : null
      }
        </div>
    </React.Fragment>
  );
}
