import React from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";



const ClientRegistration = ({ isSideBar }) => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            {isSideBar ? (
                <li className="nav-item">
                    <Link
                        className="nav-link link"
                        to={"/hirenow"}
                        onClick={() => {
                            ReactGA.event({
                                category: "Hire Developers",
                                action: "Click",
                                label: "Hire Developers",
                                value: 1,
                            })
                            document.getElementById("btn_sideNavClose").click();
                        }}
                        id="btn_sideNavClient"
                    >
                        {t("home.connectnow1")}
                    </Link>
                </li>
            ) : (
                <Link
                    className="btn nav-btn-dev"
                    id="btnDev"
                    to={"/hirenow"}
                    onClick={() => {
                        ReactGA.event({
                            category: "Hire Developers",
                            action: "Click",
                            label: "Hire Developers",
                            value: 1,
                        })
                    }}
                >
                    {t("home.connectnow1")}
                </Link>
            )}
        </React.Fragment>
    );
};

export default ClientRegistration;
