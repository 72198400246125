import React from 'react';
import { FaLocationDot } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

class Info extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zoomLevel: this.getDefaultZoomLevel(), 
            iframeKey: Date.now(),
        };
        this.resetZoom = this.resetZoom.bind(this);
    }

    componentDidMount() {
        this.props.mounted();
        window.addEventListener('resize', this.updateZoomLevel);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateZoomLevel);
    }

    getDefaultZoomLevel = () => {
        const isTabletScreen = window.innerWidth <= 767;
        return isTabletScreen ? 1 : 2;
    }

    updateZoomLevel = () => {
        this.setState({
            zoomLevel: this.getDefaultZoomLevel(),
            iframeKey: Date.now(),
        });
    }

    resetZoom() {
        this.setState({
            zoomLevel: this.getDefaultZoomLevel(),
            iframeKey: Date.now(),
        });
    }

    render() {
        const { t } = this.props;
        const { zoomLevel, iframeKey } = this.state;
        const iframeSrc = `https://www.google.com/maps/d/embed?mid=1qVPcFGQ-6MqX6lSi1guZthWXLIvR_ME&hl=en&ehbc=2E312F&z=${zoomLevel}`;

        return (
            <React.Fragment>
                <section id="contact" className="p-0" ref={(ref) => this.contactSecRef = ref}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 p-0">
                                <div className="address-box title mb-0">
                                    <div className="bg-overlay gradient-bg1 opacity-8" />
                                    <div className="address-text text-center text-white position-relative wow fadeInUp">
                                        <h2 className="mb-2">fxis.ai</h2>
                                        <h5 className="mb-4">{t("info.tagline")}</h5>
                                        <div className='d-flex justify-content-center mt-5 px-4 address-location-container'>
                                            <div style={{ width: "35%" }} className='d-flex align-items-center address-location-elm'>
                                                <div><FaLocationDot /></div>
                                                <p style={{ textAlign: "left", margin: '0', marginLeft: "30px" }} className=" mb-0 text-white">
                                                    {t("info.usadd1")}
                                                    {t("info.usadd2")}
                                                </p>
                                            </div>
                                            <div className='mx-5 address-location-mid-elm'></div>
                                            <div style={{ width: "40%" }} className='d-flex align-items-center address-location-elm'>
                                                <div><FaLocationDot /></div>
                                                <p style={{ textAlign: "left", margin: 0, marginLeft: "30px" }} className="mb-0 text-white">
                                                    {t("info.indadd1")}
                                                    {t("info.indadd2")}
                                                </p>
                                            </div>
                                        </div>

                                        <div className='d-flex justify-content-center mt-5 address-inquiry-container' style={{ gap: "40px", margin: "8px" }}>
                                            <div className="mb-3 d-flex flex-column justify-content-start align-items-start">
                                                <p className='m-0 text-white inquiry'>{t("info.business")}</p>
                                                <div className='d-flex align-items-center'>
                                                    <i className='fa fa-envelope' aria-hidden='true' />
                                                    <p style={{ margin: 0, marginLeft: "1rem" }} className='text-white mb-0 inquiry'>contact@fxis.ai</p>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <i className='fa fa-phone-square' aria-hidden='true' />
                                                    <p style={{ margin: 0, marginLeft: "1rem" }} className='text-white mb-0 inquiry'>(+91) 97279 99595</p>
                                                </div>
                                            </div>
                                            <div className="vr"></div>
                                            <div className="mb-3 d-flex flex-column justify-content-start align-items-start">
                                                <p className='m-0 text-white inquiry'>{t("info.career")}</p>
                                                <div className='d-flex align-items-center'>
                                                    <i className='fa fa-envelope' aria-hidden='true' />
                                                    <p style={{ margin: 0, marginLeft: "1rem" }} className='text-white mb-0 inquiry'>career@fxis.ai</p>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <i className='fa fa-phone-square' aria-hidden='true' />
                                                    <p style={{ margin: 0, marginLeft: "1rem" }} className='text-white mb-0 inquiry'>(+91) 98987 49300</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <i className='fa fa-clock-o' aria-hidden='true' />
                                            <p style={{ margin: 0, marginLeft: "1rem" }} className='text-white mb-0 inquiry'>{t("info.timing")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div id="map" className="half-map">
                                    <div style={{ width: "100%", height: "110%", overflow: "hidden" }} className='map-wrap'>
                                        <iframe
                                            key={iframeKey}
                                            title="map"
                                            src={iframeSrc}
                                            width="100%"
                                            height="103%"
                                            frameBorder="0"
                                            style={{ border: 'none', marginTop: -80 }}
                                        ></iframe>
                                    </div>
                                    <button 
                                        onClick={this.resetZoom} 
                                        style={{
                                            position: 'absolute',
                                            top: '10px',
                                            left: '10px',
                                            zIndex: 1,
                                            backgroundColor: 'white',
                                            border: '1px solid #ccc',
                                            padding: '5px 10px',
                                            cursor: 'pointer'
                                        }}
                                    >Reset Zoom</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const WrappedInfo = (props) => {
    const { t } = useTranslation();
    return <Info {...props} t={t} />;
};

export default WrappedInfo;
