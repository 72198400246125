import axios from "axios";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const EmailForm = ({setStatus,formReset,loading,message}) => {
    const { t } = useTranslation();
    const {
        handleSubmit,
        control,
        errors,
        formState: { isValid }
    } = useForm({
        mode: "onChange"
    });

    const emailHandler=async(data)=>{
        setStatus((prevStatus) => ({ ...prevStatus, loading: true }));
        const url = process.env.REACT_APP_APIURL + "contact";
        let headers = {
            "Content-Type": "application/json",
        };
        axios.post(url,data,{headers:headers})
        .then((res)=>{
            setStatus((prevStatus) => ({
                ...prevStatus,
                loading: false,
                success: true,
              }));
              setTimeout(() => {
                formReset();
              }, 2000);
        }).catch((err)=>{
            setStatus((prevStatus) => ({
                ...prevStatus,
                loading: false,
                error: "Something went wrong!",
              }));
        })

    }
    const onSubmit = data =>{
        emailHandler({...data,message:message})
    }
  return <form className="default-form d-flex flex-column" onSubmit={handleSubmit(onSubmit)}>
    <div className="form-group">
        <label 
            htmlFor="email"
            className="required"
            style={{marginBottom:'10px'}}
        >
            Email
        </label>
        <Controller
            as={<input style={{height:"45px"}} className={"form-control"} />}
            id={"email"}
            name={"email"}
            control={control}
            defaultValue={""}
            rules={{
                required: true,
                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            }}
        />
        <small className={"form-text text-danger"}>
        {errors.email && (
            <React.Fragment>
                {errors.email.type === "required" && (
                    <span>Email is required!</span>
                )}
                {errors.email.type === "pattern" && (
                    <span>Email is not valid</span>
                )}
            </React.Fragment>
                            )}
        </small>

    </div>
    <button type="submit" className={`btn whatsapp_phonenumber_submit_btn`} disabled={isValid?false:true} >
        {loading?<i className="fa fa-spinner fa-spin mr-2 text-white" aria-hidden="true" />:<></>}
        <span 
        style={{
            fontSize:"16px",
            fontWeight:"600"
        }}
        className="text-white" >{t("connectnowmodel.submit")}</span>
      </button>

  </form>;
};

export default EmailForm;
