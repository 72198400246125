import React from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useTranslation } from "react-i18next";
import { getCountryByTimeZone } from "../../utils/getCountryByTimezone";

const PhoneNumberField = ({ setValue, value }) => {
  const { t } = useTranslation();
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userCountry = getCountryByTimeZone(userTimeZone);
  return (
    <div className="form-group d-flex flex-column justify-content-start">
      <label
        style={{ marginBottom: "10px", textAlign: "left" }}
        htmlFor="phone_number_field"
        className="required"
      >
        {t("connectnowmodel.phonenumber")}
      </label>
      <PhoneInput
        name="phone_number_field"
        defaultCountry={userCountry.toLowerCase()}
        value={value}
        onChange={(phone, country) => {
          setValue(phone);
        }}
        forceDialCode={true}
        inputProps={{
          style: {
            width: "100%",
            fontSize: "16px",
          },
        }}
      />
    </div>
  );
};

export default PhoneNumberField;
