import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ProjectOutsourcing from "./ProjectOutsourcing";
import OnDemandDev from "./OnDemandDev";
import TechandTalent from "./TechandTalent";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
const Services1 = () => {
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const { t } = useTranslation();
  const closeModal1 = () => {
    setShowModal1(false);
  };
  const closeModal2 = () => {
    setShowModal2(false);
  };
  const closeModal3 = () => {
    setShowModal3(false);
  };
  const ServicesData = [
    {
      id: 0,
      cardTitle: "services.card1",
      cardDesc: "services.desc1",
      modalAction: setShowModal1,
      showModal: showModal1,
      closeModal: closeModal1,
      modalTitleId: "project-outsourcing-modal-title-vcenter",
      modalTitle: "projectoutsourcing.title",
      modalComponent: <ProjectOutsourcing />,
    },
    {
      id: 1,
      cardTitle: "services.card2",
      cardDesc: "services.desc2",
      modalAction: setShowModal2,
      showModal: showModal2,
      closeModal: closeModal2,
      modalTitleId: "on-demand-developer-modal-title-vcenter",
      modalTitle: "ondemanddev.title",
      modalComponent: <OnDemandDev />,
    },
    {
      id: 2,
      cardTitle: "services.card3",
      cardDesc: "services.desc3",
      modalAction: setShowModal3,
      showModal: showModal3,
      closeModal: closeModal3,
      modalTitleId: "tech-and-talent-modal-title-vcenter",
      modalTitle: "techandtalent.title",
      modalComponent: <TechandTalent />,
    },
  ];
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center wow fadeIn">
            <div className="title d-inline-block">
              <h2 className="gradient-text-default">{t("services.title")}</h2>
            </div>
          </div>
        </div>
        <div className="row" style={{display:'flex',justifyContent:'center'}}>
          <div className="price-container">
            {ServicesData.map((service, idx) => (
              <div key={idx} className="price-item item text-center">
                <h4 className="serviceTitle alt-font gradient-text1 d-inline-block">
                  {t(service.cardTitle)}
                </h4>
                <div className="price-tag">
                  <p className="price-text no-margin"> {t(service.cardDesc)}</p>
                </div>
                <div className="text-center" style={{display:"flex",justifyContent:'center'}}>
                  <button
                    className="btn btn-large serviceBtn btn-rounded w-60 p-2"
                    onClick={() => {
                      service.modalAction(true);
                      ReactGA.event({
                        category: "Hire Now",
                        action: "Click",
                        label: "Hire Now",
                        value: 1,
                      });
                    }}
                  >
                    {t("services.hirenow")}
                  </button>
                  <Modal
                    show={service.showModal}
                    onHide={service.closeModal}
                    aria-labelledby={`${service.modalTitleId}`}
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title
                        id={`${service.modalTitleId}`}
                      >
                        {t(service.modalTitle)}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {service.modalComponent}
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Services1;
