import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hire_now_register } from "../../redux/actions/sessionActions";
import "react-intl-tel-input/dist/main.css";
import { Table } from "react-bootstrap";
import PhoneNumberField from "../UI/PhoneNumberField";
import { isPhoneValid } from "../../utils/phoneNumberValidation";
import { useMediaQuery } from 'react-responsive'

function Requirements() {
    const isBetween1025And1440 = useMediaQuery({
        query: '(min-width: 1025px) and (max-width: 1440px)'
      });
      const isTabletScreen=useMediaQuery({
        query:"(max-width: 767px)"
      })
    const [phone, setPhone] = useState("");
    const [checkPhone, setCheckPhone] = useState(false);
    const [jobTimeValue, setjobTimeValue] = useState("");
    const [ventureValue, setVentureValue] = useState("");
    const [ipoValue, setIpoValue] = useState("");
    const [employeesValue, setEmployeesValue] = useState("");
    const [compSiteLink, setCompSiteLink] = useState({
        url: "",
        isTrueVal: true
    });

    const [phoneError, setPhoneError] = useState(null);
    const [radioError, setRadioError] = useState(null);

    const dispatch = useDispatch();

    const {
        errors: hireNowRegisterErrors,
        hireNowRegisterSuccessMessage,
        loaders
    } = useSelector(state => state.sessionHandler);

    const { isTrueVal, url } = compSiteLink;

    const email = useSelector(state => state.sessionHandler.email);
    const technolgies = useSelector(state => state.sessionHandler.technolgies);
    const compWebSiteLink = url;

    const data = {
        email,
        phone,
        jobTimeValue,
        ventureValue,
        ipoValue,
        employeesValue,
        compWebSiteLink,
        technolgies
    };

    const handleSubmit = e => {
        e.preventDefault();
        const checkRadio =
            jobTimeValue && ventureValue && ipoValue && employeesValue
                ? true
                : false;

        if (!checkRadio) {
            setRadioError("Please select any value");
        } else {
            setRadioError("");
        }

        if (phone === "") {
            setPhoneError("Please enter your phone number");
        } else if (!isPhoneValid(phone)) {
            setPhoneError("Please enter correct phone number");
        } else {
            setPhoneError("");
        }

        if (e.key === "Enter") {
            e.preventDefault();
        }

        if (checkRadio && checkPhone === true && isTrueVal) {
            dispatch(hire_now_register(data));
        }
    };

    const handleKeyDown = event => {
        console.log(event.key)
        if (event.key === "Enter") {
            event.preventDefault();
            handleSubmit(event);
        }
    };

    const urlPatternValidation = url => {
        const regex = new RegExp(
            "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
        );
        return regex.test(url);
    };

    const handlePhoneChange = (value, fullNumber) => {
        setPhone(fullNumber);
        setCheckPhone(value);
    };

    const handleWebsiteChange = event => {
        const { value } = event.target;
        const isTrueVal = !value || urlPatternValidation(value);
        setCompSiteLink({
            url: value,
            isTrueVal
        });
    };

    const handlejobTimeValue = event => {
        setjobTimeValue(event.target.value);
    };

    const handleVentureValue = event => {
        setVentureValue(event.target.value);
    };

    const handleIpoValue = event => {
        setIpoValue(event.target.value);
    };

    const handleEmployeesValue = event => {
        setEmployeesValue(event.target.value);
    };

    return (
        <React.Fragment>
            <div className="container-tech">
                <div className="d-flex">
                    {hireNowRegisterSuccessMessage !== null && (
                        <alert className={`alert alert-success`}>
                            {hireNowRegisterSuccessMessage}
                        </alert>
                    )}
                    {hireNowRegisterErrors.hireNowRegister !== null && (
                        <alert className={`alert alert-danger`}>
                            {hireNowRegisterErrors.hireNowRegister}
                        </alert>
                    )}
                </div>
                <h2 className="req-l1">
                    20+ Vetted Senior Software Engineers have the skills you
                    need
                </h2>

                <div className="req-con">
                    <Table borderless responsive>
                        <tbody>
                            <tr>
                                <td className="text-td">
                                    <h5>Your hiring needs.</h5>
                                </td>
                                <td className="radio-td">
                                    <div className="radio-toolbar">
                                        <input
                                            type="radio"
                                            id="100"
                                            value="parttime"
                                            name="part"
                                            onChange={handlejobTimeValue}
                                            checked={
                                                jobTimeValue === "parttime"
                                            }
                                        />
                                        <label htmlFor="100">Part-Time</label>

                                        <input
                                            type="radio"
                                            id="200"
                                            value="fulltime"
                                            name="part"
                                            onChange={handlejobTimeValue}
                                            checked={
                                                jobTimeValue === "fulltime"
                                            }
                                        />
                                        <label htmlFor="200">Full-Time</label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td className="text-td">
                                    <h5>
                                        Are you Seed or Venture Capital funded?
                                    </h5>
                                </td>
                                <td className="radio-td">
                                    <div className="radio-toolbar">
                                        <input
                                            type="radio"
                                            id="yes"
                                            value="yes"
                                            name="hope"
                                            checked={ventureValue === "yes"}
                                            onChange={handleVentureValue}
                                        />
                                        <label htmlFor="yes">Yes</label>

                                        <input
                                            type="radio"
                                            id="no"
                                            value="no"
                                            name="hope"
                                            checked={ventureValue === "no"}
                                            onChange={handleVentureValue}
                                        />
                                        <label htmlFor="no">No</label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                        <tbody>
                            <tr>
                                <td className="text-td">
                                    <h5>
                                        Are you a publicly listed/post IPO
                                        company?
                                    </h5>
                                </td>
                                <td className="radio-td">
                                    <div
                                        className="radio-toolbar"
                                        style={{ marginTop: "5px" }}
                                    >
                                        <input
                                            type="radio"
                                            id="yup"
                                            value="yes"
                                            checked={ipoValue === "yes"}
                                            name="yes"
                                            onChange={handleIpoValue}
                                        />
                                        <label htmlFor="yup">Yes</label>

                                        <input
                                            type="radio"
                                            id="none"
                                            value="no"
                                            name="yes"
                                            checked={ipoValue === "no"}
                                            onChange={handleIpoValue}
                                        />
                                        <label htmlFor="none">No</label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                        <tbody>
                            <tr>
                                <td className="text-td">
                                    <h5>Current employees in your company.</h5>
                                </td>
                                <td className="radio-td">
                                    <div
                                        className="radio-toolbar"
                                        style={{ marginTop: "5px" }}
                                    >
                                        <input
                                            type="radio"
                                            id="num1"
                                            value="1-10"
                                            checked={employeesValue === "1-10"}
                                            name="grp"
                                            onChange={handleEmployeesValue}
                                        />
                                        <label htmlFor="num1">1-10</label>

                                        <input
                                            type="radio"
                                            id="num2"
                                            value="11-50"
                                            checked={employeesValue === "11-50"}
                                            name="grp"
                                            onChange={handleEmployeesValue}
                                        />
                                        <label htmlFor="num2">11-50</label>

                                        <input
                                            type="radio"
                                            id="num3"
                                            value="51-250"
                                            checked={
                                                employeesValue === "51-250"
                                            }
                                            name="grp"
                                            onChange={handleEmployeesValue}
                                        />
                                        <label htmlFor="num3">51-250</label>

                                        <input
                                            type="radio"
                                            id="num4"
                                            value="251-10k"
                                            checked={
                                                employeesValue === "251-10k"
                                            }
                                            name="grp"
                                            onChange={handleEmployeesValue}
                                        />
                                        <label htmlFor="num4">251-10K</label>

                                        <input
                                            type="radio"
                                            id="num5"
                                            value="10k"
                                            checked={employeesValue === "10k"}
                                            name="grp"
                                            onChange={handleEmployeesValue}
                                        />
                                        <label htmlFor="num5">10K</label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                        <tbody>
                            <tr>
                                <td>
                                    <div className="invalid-feedback d-block">
                                        {radioError}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>

                <div className="req-div2">
                    <label className="star">Your email</label>
                    <input
                        className="tech-input-email"
                        type="email"
                        placeholder="Email"
                        defaultValue={email}
                        readOnly
                    ></input>

                    <div style={{width:isBetween1025And1440?"50%":isTabletScreen?"100%":"45%"}}>
                    <PhoneNumberField value={phone} setValue={setPhone}/> 
                    </div>

                    <div className="invalid-feedback d-block">{phoneError}</div>

                    <label>Company website</label>
                    <input
                        className="req-input-link"
                        type="url"
                        placeholder="Enter link here"
                        value={url}
                        onChange={handleWebsiteChange}
                        onKeyDown={handleKeyDown}
                    ></input>
                    {isTrueVal === false && (
                        <div style={{ color: "#F61C04" }}>
                            URL is not valid.
                        </div>
                    )}

                    <button
                        className="btn btn-tech"
                        disabled={loaders.hireNowRegister ? true : ""}
                        onClick={handleSubmit}
                    >
                        {loaders.hireNowRegister && (
                            <i
                                className="fa fa-spinner fa-spin mr-2"
                                aria-hidden="true"
                            />
                        )}
                        <span>
                            {loaders.hireNowRegister ? "Confirming" : "Confirm"}
                        </span>
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Requirements;
