import React, {useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {useSelector} from "react-redux";
import axios from "axios";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useTranslation } from "react-i18next";

const ProjectOutsourcing = () => {
    const { t } = useTranslation();
    const [phone, setPhone] = useState(null);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const { handleSubmit, control, getValues, formState: { errors } } = useForm();
    const {errors: loaders} = useSelector(state => state.sessionHandler)
    const onSubmit = (data) => {
        let select_dev_area = [];
        if(data.data_sci) {
            select_dev_area.push(1)
        } 
        if(data.full_stack) {
            select_dev_area.push(2);
        }
        data["dev_area"] = select_dev_area;
        const url = process.env.REACT_APP_APIURL + 'project-outsourcing';

        delete data.data_sci;
        delete data.full_stack;
        axios.post(url, data)
            .then(res => {
                if (res.data.success) {
                    setMessage(res.data);
                } else {
                    setError(res.data);
                }
            })
    };

    return (
        <>
            <section id="projectOutsourcing" className="cursor-light py-0">
                
                <div className="col-sm-12">
                    {message !== null && <div className={`alert alert-success`} style={{padding: 10}}>{message.message}</div>}
                    {error !== null && <div className={`alert alert-danger`} style={{padding: 10}}>{error.errors}</div>}
                </div>
                
                <form className="default-form"  onSubmit={handleSubmit(onSubmit)}>
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="full_name" className="required">
                                    {t("projectoutsourcing.fullname")}
                                </label>
                                <Controller
                                    as={<input className={'form-control'}/>}
                                    id={'full_name'}
                                    name={'full_name'}
                                    control={control}
                                    defaultValue={''}
                                    rules={{required: true}}
                                />
                                <small className={'form-text text-danger'}>
                                    {errors.full_name && <span>{t("projectoutsourcing.alert1")}</span>}
                                </small>
                            </div>
                        </div>
                        
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label className="required" htmlFor="email">
                                {t("projectoutsourcing.emailid")}
                                </label>
                                <Controller
                                    as={<input className={'form-control'}/>}
                                    id={'email'}
                                    name={'email'}
                                    control={control}
                                    defaultValue={''}
                                    rules={{
                                        required: true,
                                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    }}
                                />
                                <small className={'form-text text-danger'}>
                                    {errors.email &&
                                        <>
                                            {errors.email.type === 'required' && <span>{t("projectoutsourcing.alert2")}</span>}
                                            {errors.email.type === 'pattern' && <span>{t("projectoutsourcing.alert3")}</span>}
                                        </>
                                    }
                                </small>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label className="required" htmlFor="phone_number">
                                {t("projectoutsourcing.phoneno")}
                                </label>
                                <Controller
                                    as={<PhoneInput country={'in'} value={phone} onChange={(phone) => {
                                        setPhone(phone)
                                    }}/>}
                                    id={'phone_number'}
                                    name={'phone_number'}
                                    control={control}
                                    defaultValue={phone}
                                    rules={{
                                        required: true,
                                        pattern: /^((\d{1,3})([ ]*)\d{5}([- ]*)\d{5})$/
                                    }}
                                />
                                <small className={'form-text text-danger'}>
                                    {errors.phone_number &&
                                        <>
                                            {errors.phone_number.type === 'required' && <span>{t("projectoutsourcing.alert4")}</span>}
                                            {errors.phone_number.type === 'pattern' && <span>{t("projectoutsourcing.alert5")}</span>}
                                        </>
                                    }
                                </small>
                            </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label className="required" htmlFor="company">
                                {t("projectoutsourcing.companyname")}
                                </label>
                                <Controller
                                    as={<input className={'form-control'}/>}
                                    id={'company'}
                                    name={'company'}
                                    control={control}
                                    defaultValue={''}
                                    rules={{required: true}}
                                />
                                <small className={'form-text text-danger'}>
                                    {errors.company && <span>{t("projectoutsourcing.alert6")}</span>}
                                </small>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="website_url">
                                {t("projectoutsourcing.weburl")}
                                </label>
                                <Controller
                                    as={<input className={'form-control'}/>}
                                    id={'website_url'}
                                    name={'website_url'}
                                    control={control}
                                    defaultValue={''}
                                    rules={{}}
                                />
                            </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label className="required" htmlFor="budget">
                                {t("projectoutsourcing.budget")}
                                </label>
                                <Controller
                                    as={<select className="form-control">
                                            <option value="">{t("projectoutsourcing.selectbudget")}</option>
                                            <option value="US $0-$5k">US $0 To $5K</option>
                                            <option value="US $5-$10k">US $5K to $10K</option>
                                            <option value="US $10-$20k">US $10K to $20K</option>
                                            <option value="US $20-$30k">US $20K to $30K</option>
                                            <option value="US $30-$40k">US $30K to $40K</option>
                                            <option value="US $40-$50k">US $40K to $50K</option>
                                            <option value="US $50k-above">More than US $50K</option>
                                        </select>
                                    }
                                    id={'budget'}
                                    name={'budget'}
                                    control={control}
                                    defaultValue={''}
                                    rules={{required: true}}
                                />
                                <small className={'form-text text-danger'}>
                                    {errors.budget && <span>{t("projectoutsourcing.alert7")}</span>}
                                </small>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label className="required">
                                {t("projectoutsourcing.developmentarea")}
                                </label>
                                <div className="fs-14">
                                    <Controller
                                        id={'data_sci'}
                                        name={'data_sci'}
                                        control={control}
                                        defaultValue={false}
                                        rules={{validate: (value) => {
                                            return value || getValues("full_stack")
                                        }}}
                                        render={props => (
                                            <input type={'checkbox'} checked={props.value} onChange={(e) => props.onChange(e.target.checked)}/>
                                        )}
                                    />
                                    <label className="ml-2 mr-3" htmlFor="data_sci"> {t("projectoutsourcing.datascience")}</label>
                                    <Controller
                                        id={'full_stack'}
                                        name={'full_stack'}
                                        control={control}
                                        defaultValue={false}
                                        rules={{validate: (value) => {
                                            return value || getValues("data_sci")
                                        }}}
                                        render={props => (
                                            <input type={'checkbox'} checked={props.value} onChange={(e) => props.onChange(e.target.checked)}/>
                                        )}
                                    />
                                    <label className="ml-2" htmlFor="full_stack"> {t("projectoutsourcing.fullstack")}</label>
                                </div>
                                <small className={'form-text text-danger'}>
                                    {(errors.data_sci && errors.data_sci.type === "validate" && errors.full_stack && errors.full_stack.type === "validate") && <span>{t("projectoutsourcing.alert8")}</span>}
                                </small>
                            </div>
                        </div>

                        <div className="col-sm-12 text-center">
                            <button type="submit" className="btn btn-large btn-gradient btn-rounded mt-4" id="project_outsourcing_submit_btn">
                                {loaders.register && <i className="fa fa-spinner fa-spin mr-2" aria-hidden="true" disabled={loaders.register ? true : ''}/>}
                                <span>{loaders.register ? 'Submiting' : 'Submit'}</span>
                                {/* Submit */}
                            </button>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
}

export default ProjectOutsourcing;
