import React, { useEffect, useState } from "react";
import Industries from "./Industries";
import scriptLoader from "../../../utils/javascriptsLoader";
import Services1 from "./Services1";
import { useMediaQuery } from "react-responsive";
import AreaExpertics from "./AreaExpertics";

const Services = (props) => {
  const [loading, setLoading] = useState(true);
  const isTablet = useMediaQuery({ minWidth: 702, maxWidth: 1023 });
  const isBigLaptop = useMediaQuery({ minWidth: 1440 });
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  useEffect(() => {
    props.mounted();
    if (window.lazyLoadInstance) {
      window.lazyLoadInstance.update();
    }
    setTimeout(() => {
      loadProgressBarJs();
    }, 5000);
    loadIndustriesJs();
    const owlCarousel = window.$("#industries_slider").owlCarousel();
    owlCarousel.trigger("destroy.owl.carousel");
  }, [isTablet, isBigLaptop]);

  const loadProgressBarJs = () => {
    const script = {
      src:
        process.env.REACT_APP_BASE_URL +
        "js/modules/progressbar.js?ca93809a-cd54-40d0-ad2c-a763aba41895",
      async: 1,
      defer: 1,
      id: "progressbarJsId",
    };
    scriptLoader(script);
  };

  const loadIndustriesJs = () => {
    const script = {
      src:
        process.env.REACT_APP_BASE_URL +
        "js/modules/industries.js?ca93809a-cd54-40d0-ad2c-a763aba41895",
      async: 1,
      defer: 1,
      id: "industriesJsId",
    };
    scriptLoader(script, setLoading(false));
  };

  return (
    <React.Fragment>
      <section id="services" className="p-0">
        <div className="bg-light">
          <div className="divider"></div>
          <Services1 />
          <div className="divider"></div>
        </div>
        <AreaExpertics />
        <div className="row">
          <div className="container-fluid bg-light">
            <Industries
              isTablet={isTablet}
              isBigLaptop={isBigLaptop}
              loading={loading}
              isMobile={isMobile}
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Services;
