import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import ReactCountryFlag from "react-country-flag";
import { components } from 'react-select';
import { IoMdArrowDropdown } from "react-icons/io";
import { useHistory, useLocation } from "react-router-dom";

const LanguageSelector = ({navFlag,isSidebar}) => {

  const { i18n: { changeLanguage, language } } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const initialLanguage = queryParams.get('lang');
    if (initialLanguage) {
      setCurrentLanguage(initialLanguage);
      changeLanguage(initialLanguage);
    }
  }, [location.search, changeLanguage]);

  useEffect(() => {
    if (currentLanguage === 'ae' || currentLanguage === 'il') {
      document.body.style.textAlign = 'right';
    } else {
      document.body.style.textAlign = 'left';
    }
  }, [currentLanguage]);

  const handleChangeLanguage = (selectedOption) => {
    const newLanguage = selectedOption.value;
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
    history.push(`?lang=${newLanguage}`);
  };

  const languageOptions = [
    { value: 'en', label:"English", countryCode:"US"},
    { value: 'es', label: "Spain",countryCode:"ES"},
    { value: 'de', label:"Germany",countryCode:"DE"},
    { value: 'fr', label: "France",countryCode:"FR"},
    { value: 'it', label: "Italy",countryCode:"IT"},
    { value: 'pt', label: "Portugal",countryCode:"PT" },
    { value: 'jp', label: "Japan",countryCode:"JP" },
    { value: 'au', label: "Australia",countryCode:"AU" },
    { value: 'ae', label: "Arabic",countryCode:"AE" },
    { value: 'il', label: "Hebrew",countryCode:"IL" },
    { value: 'th', label: "Thai",countryCode:"TH" },
  ];

  const SingleValue = ({ data }) => (
    <div style={{width:"2rem",height:"1.5rem",overflow:"hidden"}}>
       <ReactCountryFlag
      countryCode={data.countryCode}
      svg
      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      aria-label={data.label}
    />
    </div>
  );

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div style={{width:'100%',display:"flex",gap:"10px",alignItems:"center"
      }}>
        <div style={{width:"24px",height:"16px",background:"red",position:"relative"}}>
        <ReactCountryFlag
        countryCode={props.data.countryCode}
        svg
        style={{ width: '100%', height: '100%', objectFit: 'cover',position:"absolute",transform:"translate(-50%,-50%)",left:"50%",top:"50%" }}
        aria-label={props.data.label}
      />

        </div>
        <p style={{fontWeight:"600",fontSize:"14px",color:"inherit"}} className="m-0">{props.data.label}</p>
        </div>

      </components.Option>
    );
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <IoMdArrowDropdown style={{color:navFlag?"#fff":"#000",fontSize:"20px",cursor:"pointer"}} />
      </components.DropdownIndicator>
    );
  };

  const commonSelectStyles = {
    container: (provided) => ({
      ...provided,
      marginLeft:isSidebar?"0": '30px',
    }),
    control: (provider) => ({
      ...provider,
      padding:"0",
      background: "transparent",
      border: "none",
      outline: "none",
      borderColor: "transparent",
      boxShadow: "none",
      cursor: "pointer",
      ":hover": {
        border: "none",
        outline: "none",
      },
      ":active": {
        border: "none",
        outline: "none",
      },
    }),
    valueContainer: (provided) => ({
      height: "32px",

    }),
    indicatorSeparator: (provided) => ({
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#5928d1" : provided.backgroundColor,
      color: state.isSelected ? "#fff" : "#000",
      cursor: "pointer",
      ":hover": {
        backgroundColor: state.isSelected ? "#5928d1" : "rgba(127, 17, 224, 0.18)",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      width: "155px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0",
    })
  };
  return <Select
  value={languageOptions.find(option => option.value === currentLanguage)}
  onChange={handleChangeLanguage}
  options={languageOptions}
  className="language-select"
  isSearchable={false}
  styles={commonSelectStyles}
  components={{ SingleValue, Option, DropdownIndicator }}
/>;
};

export default LanguageSelector;
