import React, { useEffect,useState } from "react";
import TestimonialCard from "./TestimonialCard";
import { testimonialData } from "../../../utils/constants";
import "./testimonial.css";
import { useTranslation } from "react-i18next";
import scriptLoader from "../../../utils/javascriptsLoader";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";

const Testimonial = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  
  useEffect(() => {
    props.mounted();
    loadCounterJs();
  }, []);

  const loadCounterJs = () => {
    const testimonialScript = {
      src:
        process.env.REACT_APP_BASE_URL +
        "js/modules/testimonial.js?ca93809a-cd54-40d0-ad2c-a763aba41856",
      async: 1,
      defer: 1,
      id: "testimonialId",
    };
    scriptLoader(testimonialScript, onScriptLoad);
  };

  const onScriptLoad = () => {
    if (window.lazyLoadInstance) {
      window.lazyLoadInstance.update();
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <section id="testimonial">
        <div className="container-fluid text-center">
          <div className="col-md-12">
            <Skeleton height={40} width={`30%`} />
          </div>
          {isMobile ? (
            <div className="row testimonial_slide" style={{ margin: "2rem 0" }}>
              <div className="col-md-12 d-flex justify-content-center">
                <div
                  className="skeleton-container"
                  style={{ width: "100%", display: "flex" }}
                >
                  <div
                    className="center-skeleton"
                    style={{ flex: 1, padding: "0 1rem" }}
                  >
                    <Skeleton height={350} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row testimonial_slide" style={{ margin: "2rem 0" }}>
              <div className="col-md-12 d-flex justify-content-center">
                <div
                  className="skeleton-container"
                  style={{ width: "100%", display: "flex" }}
                >
                  <div
                    className="left-skeleton"
                    style={{ flex: 1, padding: "0 1rem" }}
                  >
                    <Skeleton height={275} />
                  </div>
                  <div
                    className="center-skeleton"
                    style={{ flex: 1, padding: "0 1rem" }}
                  >
                    <Skeleton height={350} />
                  </div>
                  <div
                    className="right-skeleton"
                    style={{ flex: 1, padding: "0 1rem" }}
                  >
                    <Skeleton height={275} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-12 text-center">
          <Skeleton height={15} width={`20%`} />
        </div>
      </section>
    );
  }

  return (
    <section id="testimonial">
      <div className="container-fluid text-center">
        <div className="col-md-12">
          <div className="title d-inline-block">
            <h2 className="gradient-text-default">{t("testimonial.title")}</h2>
          </div>
        </div>
        <div className="row testimonial_slide">
          <div id="testimonial_slider" className="owl-carousel">
            {testimonialData.map((testimonial, index) => (
              <div
                key={index}
                className="testimonial-item"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TestimonialCard
                  quote={testimonial.quote}
                  text={testimonial.text}
                  logo={testimonial.logo}
                  location={testimonial.location}
                  industry={testimonial.industry}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
