export const DEFAULT_TABS = [
  // {
  //     id: 1,
  //     title: 'Home',
  //     path: '#home',
  //     partialParentPath: '/'
  // },
  // {
  //     id: 2,
  //     title: 'About Us',
  //     path: '#about',
  //     partialParentPath: '/'
  // },
  // {
  //     id: 3,
  //     title: 'Services',
  //     path: '#services',
  //     div_id: 's_dropdown',
  //     class: 's-dropdown',
  //     icon_id: 'service_dropdown_toggle',
  //     subTabs: [
  //         {title: 'Services We Offer', path: '#services', partialParentPath: '/'},
  //         {title: 'Industries We Work In', path: '#industries', partialParentPath: '/'},
  //         {title: ' Technologies We Use', path: '#technologies', partialParentPath: '/'},
  //     ],
  //     partialParentPath: '/'
  // },
  // {
  //     id: 4,
  //     title: 'Products',
  //     path: '#products',
  //     div_id: 'p_dropdown',
  //     class: 's-dropdown',
  //     icon_id: 'product_dropdown_toggle',
  //     subTabs: [
  //         {title: 'H+Tree', path: '#p_hplus_tree', partialParentPath: '/'},
  //         {title: 'F(x) Data Cloud', path: '#p_fx_data_clouds', partialParentPath: '/'},
  //         {title: 'F(x) Optimizer', path: '#p_fx_optimizer', partialParentPath: '/'},
  //         {title: 'F(x) ERP', path: '#p_fx_erp', partialParentPath: '/'},
  //     ],
  //     partialParentPath: '/'
  // },
  // {
  //     id: 5,
  //     title: 'Contact Us',
  //     path: '#contact',
  //     partialParentPath: '/'
  // },
];

export const PHASE_STATUS_CLASSES = {
  1: "pending",
  2: "progress",
  3: "rejected",
  4: "completed",
};

export const TIMELINE_TAIL_STATUS_CLASSES = {
  1: "pending",
  2: "progress",
  3: "pending",
  4: "completed",
};

export const USER_PROGRESS_STATUS_CLASSES = {
  1: "secondary",
  2: "success",
  3: "danger",
  4: "light",
};

export const MAX_FILE_UPLOAD_SIZE = 50;

export const MAX_PAGINATION_ITEMS = 5;

export const DEVELOPER_PROCESS_STATUS = [
  { name: "In Process", value: 2 },
  { name: "Rejected", value: 3 },
  { name: "Completed", value: 4 },
];
export const HireExExpertsCompany = [
  {
    id: 0,
    row_1_images: [
      "../images/hireexexperts/slide_1/1_row/1.webp",
      "../images/hireexexperts/slide_1/1_row/2.webp",
      "../images/hireexexperts/slide_1/1_row/6.webp",
      "../images/hireexexperts/slide_1/1_row/7.webp",
      "../images/hireexexperts/slide_1/1_row/7203.webp",
      "../images/hireexexperts/slide_1/2_row/7204.webp",
      "../images/hireexexperts/slide_1/2_row/7205.webp",
    ],

    row_2_images: [
      
      "../images/hireexexperts/slide_1/2_row/7206.webp",
      "../images/hireexexperts/slide_1/2_row/7207.webp",
      "../images/hireexexperts/slide_1/2_row/7208.webp",
      "../images/hireexexperts/slide_2/1_row/2.webp",
      "../images/hireexexperts/slide_2/1_row/1.webp",
      "../images/hireexexperts/slide_2/1_row/7212.webp",
      "../images/hireexexperts/slide_2/1_row/3.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_2/1_row/7209.webp",
      "../images/hireexexperts/slide_2/2_row/5.webp",
      "../images/hireexexperts/slide_2/2_row/7205.webp",
      "../images/hireexexperts/slide_2/2_row/4.webp",
      "../images/hireexexperts/slide_2/2_row/7210.webp",
      "../images/hireexexperts/slide_2/2_row/7211.webp",
      "../images/hireexexperts/slide_3/1_row/7212.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/hireexexperts/slide_3/1_row/1.webp",
      "../images/hireexexperts/slide_3/1_row/7208.webp",
      "../images/hireexexperts/slide_3/1_row/4.webp",
      "../images/hireexexperts/slide_3/1_row/7214.webp",
      "../images/hireexexperts/slide_3/2_row/2.webp",
      "../images/hireexexperts/slide_3/2_row/3.webp",
      "../images/hireexexperts/slide_3/2_row/4.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_3/2_row/5.webp",
      "../images/hireexexperts/slide_3/2_row/7215.webp",
      "../images/hireexexperts/slide_4/1_row/7217.webp",
      "../images/hireexexperts/slide_4/1_row/7225.webp",
      "../images/hireexexperts/slide_4/1_row/7218.webp",
      "../images/hireexexperts/slide_4/1_row/7219.webp",
      "../images/hireexexperts/slide_4/1_row/1.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_4/2_row/7220.webp",
      "../images/hireexexperts/slide_4/2_row/7221.webp",
      "../images/hireexexperts/slide_4/2_row/7222.webp",
      "../images/hireexexperts/slide_4/2_row/7223.webp",
      "../images/hireexexperts/slide_4/2_row/7224.webp",
      "../images/hireexexperts/slide_5/1_row/7221.webp",
      "../images/hireexexperts/slide_5/1_row/7220.webp",
    ],
  },
];

export const HireExExpertsCompanyTablet = [
  {
    id: 0,
    row_1_images: [
      "../images/hireexexperts/slide_1/1_row/1.webp",
      "../images/hireexexperts/slide_1/1_row/2.webp",
      "../images/hireexexperts/slide_1/1_row/6.webp",
      "../images/hireexexperts/slide_1/1_row/7.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_1/1_row/7203.webp",
      "../images/hireexexperts/slide_1/2_row/7204.webp",
      "../images/hireexexperts/slide_1/2_row/7205.webp",
      "../images/hireexexperts/slide_1/2_row/7206.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_1/2_row/7207.webp",
      "../images/hireexexperts/slide_1/2_row/7208.webp",
      "../images/hireexexperts/slide_2/1_row/2.webp",
      "../images/hireexexperts/slide_2/1_row/1.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/hireexexperts/slide_2/1_row/7212.webp",
      "../images/hireexexperts/slide_2/1_row/3.webp",
      "../images/hireexexperts/slide_2/1_row/7209.webp",
      "../images/hireexexperts/slide_2/2_row/5.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_2/2_row/7205.webp",
      "../images/hireexexperts/slide_2/2_row/4.webp",
      "../images/hireexexperts/slide_2/2_row/7210.webp",
      "../images/hireexexperts/slide_2/2_row/7211.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_3/1_row/7212.webp",
      "../images/hireexexperts/slide_3/1_row/1.webp",
      "../images/hireexexperts/slide_3/1_row/7208.webp",
      "../images/hireexexperts/slide_3/1_row/4.webp",
    ],
  },
  {
    id: 2,
    row_1_images: [
      "../images/hireexexperts/slide_3/1_row/7214.webp",
      "../images/hireexexperts/slide_3/2_row/2.webp",
      "../images/hireexexperts/slide_3/2_row/3.webp",
      "../images/hireexexperts/slide_3/2_row/4.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_3/2_row/5.webp",
      "../images/hireexexperts/slide_3/2_row/7215.webp",
      "../images/hireexexperts/slide_4/1_row/7217.webp",
      "../images/hireexexperts/slide_4/1_row/7225.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_4/1_row/7218.webp",
      "../images/hireexexperts/slide_4/1_row/7219.webp",
      "../images/hireexexperts/slide_4/1_row/1.webp",
      "../images/hireexexperts/slide_4/2_row/7220.webp",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "../images/hireexexperts/slide_4/2_row/7221.webp",
      "../images/hireexexperts/slide_4/2_row/7222.webp",
      "../images/hireexexperts/slide_4/2_row/7223.webp",
      "../images/hireexexperts/slide_4/2_row/7224.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_5/1_row/7221.webp",
      "../images/hireexexperts/slide_5/1_row/7220.webp",
      "../images/hireexexperts/slide_5/1_row/7222.webp",
      "../images/hireexexperts/slide_5/1_row/7223.webp",
    ],

    row_3_images: ["../images/hireexexperts/slide_5/1_row/7224.webp"],
  },
];

export const HireExExpertsCompanyMobile = [
  {
    id: 0,
    row_1_images: [
      "../images/hireexexperts/slide_1/1_row/1.webp",
      "../images/hireexexperts/slide_1/1_row/2.webp",
      "../images/hireexexperts/slide_1/1_row/6.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_1/1_row/7.webp",
      "../images/hireexexperts/slide_1/1_row/7203.webp",
      "../images/hireexexperts/slide_1/2_row/7204.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_1/2_row/7205.webp",
      "../images/hireexexperts/slide_1/2_row/7206.webp",
      "../images/hireexexperts/slide_1/2_row/7207.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/hireexexperts/slide_1/2_row/7208.webp",
      "../images/hireexexperts/slide_2/1_row/2.webp",
      "../images/hireexexperts/slide_2/1_row/1.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_2/1_row/7212.webp",
      "../images/hireexexperts/slide_2/1_row/3.webp",
      "../images/hireexexperts/slide_2/1_row/7209.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_2/2_row/5.webp",
      "../images/hireexexperts/slide_2/2_row/7205.webp",
      "../images/hireexexperts/slide_2/2_row/4.webp",
    ],
  },
  {
    id: 2,
    row_1_images: [
      "../images/hireexexperts/slide_2/2_row/7210.webp",
      "../images/hireexexperts/slide_2/2_row/7211.webp",
      "../images/hireexexperts/slide_3/1_row/7212.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_3/1_row/1.webp",
      "../images/hireexexperts/slide_3/1_row/7208.webp",
      "../images/hireexexperts/slide_3/1_row/4.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_3/1_row/7214.webp",
      "../images/hireexexperts/slide_3/2_row/2.webp",
      "../images/hireexexperts/slide_3/2_row/3.webp",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "../images/hireexexperts/slide_3/2_row/4.webp",
      "../images/hireexexperts/slide_3/2_row/5.webp",
      "../images/hireexexperts/slide_3/2_row/7215.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_4/1_row/7217.webp",
      "../images/hireexexperts/slide_4/1_row/7225.webp",
      "../images/hireexexperts/slide_4/1_row/7218.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_4/1_row/7219.webp",
      "../images/hireexexperts/slide_4/1_row/1.webp",
      "../images/hireexexperts/slide_4/2_row/7220.webp",
    ],
  },
  {
    id: 4,
    row_1_images: [
      "../images/hireexexperts/slide_4/2_row/7221.webp",
      "../images/hireexexperts/slide_4/2_row/7222.webp",
      "../images/hireexexperts/slide_4/2_row/7223.webp",
    ],

    row_2_images: [
      "../images/hireexexperts/slide_4/2_row/7224.webp",
      "../images/hireexexperts/slide_5/1_row/7221.webp",
      "../images/hireexexperts/slide_5/1_row/7220.webp",
    ],

    row_3_images: [
      "../images/hireexexperts/slide_5/1_row/7222.webp",
      "../images/hireexexperts/slide_5/1_row/7223.webp",
      "../images/hireexexperts/slide_5/1_row/7224.webp",
    ],
  },
];

export const Awards = [
  {
    id: 0,
    row_1_images: [
      "../images/awards/slide_1/row_1/Group_1000001407.webp",
      "../images/awards/slide_1/row_1/Group_1000001405.webp",
      "../images/awards/slide_1/row_1/Group_1000001404.webp",
      "../images/awards/slide_1/row_1/Group_1000001406.webp",
    ],

    row_2_images: [
      "../images/awards/slide_1/row_1/Group_1000001402.webp",
      "../images/awards/slide_2/row_1/Group_1000001407.webp",
      "../images/awards/slide_1/row_2/Group_1000001412.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/awards/slide_1/row_2/Group_1000001409.webp",
      "../images/awards/slide_1/row_2/Group_1000001410.webp",
      "../images/awards/slide_1/row_2/Group_1000001411.webp",
      "../images/awards/slide_1/row_2/Group_1000001405.webp",
    ],

    row_2_images: [
      "../images/awards/slide_2/row_1/Group_1000001405.webp",
      "../images/awards/slide_2/row_1/Group_1000001404.webp",
      "../images/awards/slide_2/row_1/Group_1000001406.webp",
    ],
  },
  {
    id: 2,
    row_1_images: [
      "../images/awards/slide_2/row_1/Group_1000001402.webp",
      "../images/awards/slide_2/row_2/Group_1000001407.webp",
      "../images/awards/slide_2/row_2/Group_1000001404.webp",
      "../images/awards/slide_2/row_2/Group_1000001406.webp",
    ],

    row_2_images: [
      "../images/awards/slide_3/row_1/Group_1000001407.webp",
      "../images/awards/slide_2/row_2/Group_1000001402.webp",
      "../images/awards/slide_2/row_2/Group_1000001405.webp",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "../images/awards/slide_3/row_2/Group_1000001407.webp",
      "../images/awards/slide_3/row_2/Group_1000001405.webp",
      "../images/awards/slide_3/row_2/Group_1000001404.webp",
      "../images/awards/slide_3/row_1/Group_1000001402.webp",
    ],

    row_2_images: [
      "../images/awards/slide_3/row_1/Group_1000001405.webp",
      "../images/awards/slide_3/row_1/Group_1000001404.webp",
      "../images/awards/slide_3/row_1/Group_1000001406.webp",
    ],
  },

  {
    id: 4,
    row_1_images: [
      "../images/awards/slide_3/row_2/Group_1000001406.webp",
      "../images/awards/slide_3/row_2/Group_1000001402.webp",
      "../images/awards/slide_4/row_1/Group_1000001407.webp",
      "../images/awards/slide_4/row_1/Group_1000001405.webp",
    ],

    row_2_images: [
      "../images/awards/slide_4/row_1/Group_1000001404.webp",
      "../images/awards/slide_4/row_1/Group_1000001406.webp",
      "../images/awards/slide_4/row_1/Group_1000001402.webp",
    ],
  },

  {
    id: 5,
    row_1_images: [
      "../images/awards/slide_4/row_2/Group_1000001407.webp",
      "../images/awards/slide_4/row_2/Group_1000001405.webp",
      "../images/awards/slide_4/row_2/Group_1000001408.webp",
      "../images/awards/slide_4/row_2/Group_1000001409.webp",
    ],

    row_2_images: [
      "../images/awards/slide_4/row_2/Group_1000001402.webp",
      "../images/awards/slide_5/row_1/Group_1000001407.webp",
      "../images/awards/slide_5/row_1/Group_1000001405.webp",
    ],
  },

  {
    id: 6,
    row_1_images: [
      "../images/awards/slide_5/row_1/Group_1000001404.webp",
      "../images/awards/slide_5/row_1/Group_1000001406.webp",
      "../images/awards/slide_5/row_1/Group_1000001402.webp",
      "../images/awards/slide_5/row_2/Group_1000001407.webp",
    ],

    row_2_images: [
      "../images/awards/slide_5/row_2/Group_1000001405.webp",
      "../images/awards/slide_5/row_2/Group_1000001408.webp",
      "../images/awards/slide_5/row_2/Group_1000001409.webp",
    ],
  },

  {
    id: 7,
    row_1_images: [
      "../images/awards/slide_5/row_2/Group_1000001402.webp",
      "../images/awards/slide_6/row_1/Group_1000001407.webp",
      "../images/awards/slide_6/row_1/Group_1000001404.webp",
      "../images/awards/slide_6/row_1/Group_1000001405.webp",
    ],

    row_2_images: [
      "../images/awards/slide_6/row_1/Group_1000001406.webp",
      "../images/awards/slide_6/row_1/Group_1000001402.webp",
      "../images/awards/slide_6/row_2/Group_1000001407.webp",
    ],
  },

  {
    id: 8,
    row_1_images: [
      "../images/awards/slide_6/row_2/Group_1000001405.webp",
      "../images/awards/slide_6/row_2/Group_1000001408.webp",
      "../images/awards/slide_6/row_2/Group_1000001409.webp",
    ],

    row_2_images: [],
  },
];

export const AwardsMobile = [
  {
    id: 0,
    row_1_images: [
      "../images/awards/slide_1/row_1/Group_1000001407.webp",
      "../images/awards/slide_1/row_1/Group_1000001405.webp",
      "../images/awards/slide_1/row_1/Group_1000001404.webp",
    ],

    row_2_images: [
      "../images/awards/slide_1/row_1/Group_1000001406.webp",
      "../images/awards/slide_1/row_1/Group_1000001402.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/awards/slide_2/row_1/Group_1000001407.webp",
      "../images/awards/slide_1/row_2/Group_1000001412.webp",
      "../images/awards/slide_1/row_2/Group_1000001409.webp",
    ],

    row_2_images: [
      "../images/awards/slide_1/row_2/Group_1000001410.webp",
      "../images/awards/slide_1/row_2/Group_1000001411.webp",
    ],
  },
  {
    id: 2,
    row_1_images: [
      "../images/awards/slide_1/row_2/Group_1000001405.webp",
      "../images/awards/slide_2/row_1/Group_1000001405.webp",
      "../images/awards/slide_2/row_1/Group_1000001404.webp",
    ],

    row_2_images: [
      "../images/awards/slide_2/row_1/Group_1000001406.webp",
      "../images/awards/slide_2/row_1/Group_1000001402.webp",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "../images/awards/slide_2/row_2/Group_1000001407.webp",
      "../images/awards/slide_2/row_2/Group_1000001404.webp",
      "../images/awards/slide_2/row_2/Group_1000001406.webp",
    ],

    row_2_images: [
      "../images/awards/slide_3/row_1/Group_1000001407.webp",
      "../images/awards/slide_2/row_2/Group_1000001402.webp",
    ],
  },

  {
    id: 4,
    row_1_images: [
      "../images/awards/slide_2/row_2/Group_1000001405.webp",
      "../images/awards/slide_3/row_2/Group_1000001407.webp",
      "../images/awards/slide_3/row_2/Group_1000001405.webp",
    ],

    row_2_images: [
      "../images/awards/slide_3/row_2/Group_1000001404.webp",
      "../images/awards/slide_3/row_1/Group_1000001402.webp",
    ],
  },

  {
    id: 5,
    row_1_images: [
      "../images/awards/slide_3/row_1/Group_1000001405.webp",
      "../images/awards/slide_3/row_1/Group_1000001404.webp",
      "../images/awards/slide_3/row_1/Group_1000001406.webp",
    ],

    row_2_images: [
      "../images/awards/slide_3/row_2/Group_1000001406.webp",
      "../images/awards/slide_3/row_2/Group_1000001402.webp",
    ],
  },

  {
    id: 6,
    row_1_images: [
      "../images/awards/slide_4/row_1/Group_1000001407.webp",
      "../images/awards/slide_4/row_1/Group_1000001405.webp",
      "../images/awards/slide_4/row_1/Group_1000001404.webp",
    ],

    row_2_images: [
      "../images/awards/slide_4/row_1/Group_1000001406.webp",
      "../images/awards/slide_4/row_1/Group_1000001402.webp",
    ],
  },

  {
    id: 7,
    row_1_images: [
      "../images/awards/slide_4/row_2/Group_1000001407.webp",
      "../images/awards/slide_4/row_2/Group_1000001405.webp",
      "../images/awards/slide_4/row_2/Group_1000001408.webp",
    ],

    row_2_images: [
      "../images/awards/slide_4/row_2/Group_1000001409.webp",
      "../images/awards/slide_4/row_2/Group_1000001402.webp",
    ],
  },

  {
    id: 8,
    row_1_images: [
      "../images/awards/slide_5/row_1/Group_1000001407.webp",
      "../images/awards/slide_5/row_1/Group_1000001405.webp",
      "../images/awards/slide_5/row_1/Group_1000001404.webp",
    ],

    row_2_images: [
      "../images/awards/slide_5/row_1/Group_1000001406.webp",
      "../images/awards/slide_5/row_1/Group_1000001402.webp",
    ],
  },
  {
    id: 9,
    row_1_images: [
      "../images/awards/slide_5/row_2/Group_1000001407.webp",
      "../images/awards/slide_5/row_2/Group_1000001405.webp",
      "../images/awards/slide_5/row_2/Group_1000001408.webp",
    ],

    row_2_images: [
      "../images/awards/slide_5/row_2/Group_1000001409.webp",
      "../images/awards/slide_5/row_2/Group_1000001402.webp",
    ],
  },
  {
    id: 10,
    row_1_images: [
      "../images/awards/slide_6/row_1/Group_1000001407.webp",
      "../images/awards/slide_6/row_1/Group_1000001404.webp",
      "../images/awards/slide_6/row_1/Group_1000001405.webp",
    ],

    row_2_images: [
      "../images/awards/slide_6/row_1/Group_1000001406.webp",
      "../images/awards/slide_6/row_1/Group_1000001402.webp",
    ],
  },
  {
    id: 11,
    row_1_images: [
      "../images/awards/slide_6/row_2/Group_1000001407.webp",
      "../images/awards/slide_6/row_1/Group_1000001406.webp",
      "../images/awards/slide_6/row_1/Group_1000001402.webp",
    ],

    row_2_images: ["../images/awards/slide_6/row_2/Group_1000001407.webp"],
  },
];

export const clienteleData = [
  {
    id: 0,
    row_1_images: [
      "../images/clientele/slide_1/row_1/Group_1000001459.webp",
      "../images/clientele/slide_1/row_1/image_170.webp",
      "../images/clientele/slide_1/row_1/Mask_group.webp",
      "../images/clientele/slide_1/row_1/yes-referral-logo_1.webp",
    ],
    row_2_images: [
      "../images/clientele/slide_1/row_2/image_188.webp",
      "../images/clientele/slide_1/row_2/image_189.webp",
      "../images/clientele/slide_1/row_2/image_190.webp",
      "../images/clientele/slide_1/row_2/Mask_group.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/clientele/slide_2/row_1/image_179.webp",
      "../images/clientele/slide_2/row_1/image_196.webp",
      "../images/clientele/slide_2/row_1/image_197.webp",
      "../images/clientele/slide_2/row_1/image_198.webp",
    ],
    row_2_images: [
      "../images/clientele/slide_2/row_2/image_199.webp",
      "../images/clientele/slide_2/row_2/image_200.webp",
      "../images/clientele/slide_2/row_2/image_201.webp",
      "../images/clientele/slide_2/row_2/image_202.webp",
    ],
  },
  {
    id: 2,
    row_1_images: [
      "../images/clientele/slide_3/row_1/Group_1000001942.webp",
      "../images/clientele/slide_3/row_1/image_203.webp",
      "../images/clientele/slide_3/row_1/image_206.webp",
      "../images/clientele/slide_3/row_1/image_208.webp",
    ],
    row_2_images: [
      "../images/clientele/slide_3/row_2/image_209.webp",
      "../images/clientele/slide_3/row_2/image_210.webp",
      "../images/clientele/slide_3/row_2/image_211.webp",
      "../images/clientele/slide_3/row_2/image_212.webp",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "../images/clientele/slide_4/row_1/Group_1000001943.webp",
      "../images/clientele/slide_4/row_1/image_213.webp",
      "../images/clientele/slide_4/row_1/image_216.webp",
      "../images/clientele/slide_4/row_1/image_217.webp",
    ],
    row_2_images: [
      "../images/clientele/slide_4/row_2/image_218.webp",
      "../images/clientele/slide_4/row_2/image_219.webp",
      "../images/clientele/slide_4/row_2/image_220.webp",
      "../images/clientele/slide_4/row_2/image_221.webp",
    ],
  },
  {
    id: 4,
    row_1_images: [
      "../images/clientele/slide_5/row_1/image_222.webp",
      "../images/clientele/slide_5/row_1/image_223.webp",
      "../images/clientele/slide_5/row_1/image_224.webp",
      "../images/clientele/slide_5/row_1/image_225.webp",
    ],
    row_2_images: [
      "../images/clientele/slide_5/row_2/image_226.webp",
      "../images/clientele/slide_5/row_2/image_227.webp",
      "../images/clientele/slide_5/row_2/image_228.webp",
      "../images/clientele/slide_5/row_2/image_229.webp",
    ],
  },
];
export const clienteleMobileData = [
  {
    id: 0,
    row_1_images: [
      "../images/clientele/slide_1/row_1/Group_1000001459.webp",
      "../images/clientele/slide_1/row_1/image_170.webp",
      "../images/clientele/slide_1/row_1/Mask_group.webp",
    ],
    row_2_images: [
      "../images/clientele/slide_1/row_1/yes-referral-logo_1.webp",
      "../images/clientele/slide_1/row_2/image_188.webp",
      "../images/clientele/slide_1/row_2/image_189.webp",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "../images/clientele/slide_1/row_2/image_190.webp",
      "../images/clientele/slide_1/row_2/Mask_group.webp",
      "../images/clientele/slide_2/row_1/image_179.webp",
    ],
    row_2_images: [
      "../images/clientele/slide_2/row_1/image_196.webp",
      "../images/clientele/slide_2/row_1/image_197.webp",
      "../images/clientele/slide_2/row_1/image_198.webp",
    ],
  },
  {
    id: 2,
    row_1_images: [
      "../images/clientele/slide_2/row_2/image_199.webp",
      "../images/clientele/slide_2/row_2/image_200.webp",
      "../images/clientele/slide_2/row_2/image_201.webp",
    ],
    row_2_images: [
      "../images/clientele/slide_2/row_2/image_202.webp",
      "../images/clientele/slide_3/row_1/Group_1000001942.webp",
      "../images/clientele/slide_3/row_1/image_203.webp",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "../images/clientele/slide_3/row_1/image_206.webp",
      "../images/clientele/slide_3/row_1/image_208.webp",
      "../images/clientele/slide_3/row_2/image_209.webp",
    ],
    row_2_images: [
      "../images/clientele/slide_3/row_2/image_210.webp",
      "../images/clientele/slide_3/row_2/image_211.webp",
      "../images/clientele/slide_3/row_2/image_212.webp",
    ],
  },
  {
    id: 4,
    row_1_images: [
      "../images/clientele/slide_4/row_1/Group_1000001943.webp",
      "../images/clientele/slide_4/row_1/image_213.webp",
      "../images/clientele/slide_4/row_1/image_216.webp",
    ],
    row_2_images: [
      "../images/clientele/slide_4/row_1/image_217.webp",
      "../images/clientele/slide_4/row_2/image_218.webp",
      "../images/clientele/slide_4/row_2/image_219.webp",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "../images/clientele/slide_4/row_2/image_220.webp",
      "../images/clientele/slide_4/row_2/image_221.webp",
      "../images/clientele/slide_5/row_1/image_222.webp",
    ],
    row_2_images: [
      "../images/clientele/slide_5/row_1/image_223.webp",
      "../images/clientele/slide_5/row_1/image_224.webp",
      "../images/clientele/slide_5/row_1/image_225.webp",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "../images/clientele/slide_5/row_2/image_226.webp",
      "../images/clientele/slide_5/row_2/image_227.webp",
      "../images/clientele/slide_5/row_2/image_228.webp",
    ],
    row_2_images: [
      "../images/clientele/slide_5/row_2/image_229.webp",
    ],
  },
   {
    id: 3,
    row_1_images: [
      "../images/clientele/slide_3/row_1/image_206.webp",
      "../images/clientele/slide_3/row_1/image_208.webp",
      "../images/clientele/slide_3/row_2/image_209.webp",
    ],
    row_2_images: [
      "../images/clientele/slide_3/row_2/image_210.webp",
      "../images/clientele/slide_3/row_2/image_211.webp",
      "../images/clientele/slide_3/row_2/image_212.webp",
    ],
  },
];
export const testimonialData = [
  {
    quote: "testimonial.quote1",
    text: "testimonial.text1",
    logo: "../images/testimonial/pareit.webp",
    location: "testimonial.location1",
    industry: "testimonial.industry1",
  },
  {
    quote: "testimonial.quote2",
    text: "testimonial.text2",
    logo: "../images/testimonial/actiontoyrental.webp",
    location: "testimonial.location2",
    industry: "testimonial.industry2",
  },
  {
    quote: "testimonial.quote3",
    text: "testimonial.text3",
    logo: "../images/testimonial/doganai.webp",
    location: "testimonial.location3",
    industry: "testimonial.industry3",
  },
  {
    quote: "testimonial.quote4",
    text: "testimonial.text4",
    logo: "../images/testimonial/nok.webp",
    location: "testimonial.location4",
    industry: "testimonial.industry4",
  },
  {
    quote: "testimonial.quote5",
    text: "testimonial.text5",
    logo: "../images/testimonial/parasinterior.webp",
    location: "testimonial.location5",
    industry: "testimonial.industry5",
  },
  {
    quote: "testimonial.quote6",
    text: "testimonial.text6",
    logo: "../images/testimonial/mobiloutdoormedia.webp",
    location: "testimonial.location6",
    industry: "testimonial.industry6",
  },
  {
    quote: "testimonial.quote7",
    text: "testimonial.text7",
    logo: "../images/testimonial/solarisfinance.webp",
    location: "testimonial.location7",
    industry: "testimonial.industry7",
  },
  {
    quote: "testimonial.quote8",
    text: "testimonial.text8",
    logo: "../images/testimonial/tailorspoint.webp",
    location: "testimonial.location8",
    industry: "testimonial.industry8",
  },
  {
    quote: "testimonial.quote9",
    text: "testimonial.text9",
    logo: "../images/testimonial/vvictortechnology.webp",
    location: "testimonial.location9",
    industry: "testimonial.industry9",
  },
  {
    quote: "testimonial.quote10",
    text: "testimonial.text10",
    logo: "../images/testimonial/valunova.webp",
    location: "testimonial.location10",
    industry: "testimonial.industry10",
  },
  {
    quote: "testimonial.quote11",
    text: "testimonial.text11",
    logo: "../images/testimonial/wetals.webp",
    location: "testimonial.location11",
    industry: "testimonial.industry11",
  },
  {
    quote: "testimonial.quote12",
    text: "testimonial.text12",
    logo: "../images/testimonial/weteams1.webp",
    location: "testimonial.location12",
    industry: "testimonial.industry12",
  },
  {
    quote: "testimonial.quote13",
    text: "testimonial.text13",
    logo: "../images/testimonial/supersourcing.webp",
    location: "testimonial.location13",
    industry: "testimonial.industry13",
  },
  {
    quote: "testimonial.quote14",
    text: "testimonial.text14",
    logo: "../images/testimonial/sumtracker.webp",
    location: "testimonial.location14",
    industry: "testimonial.industry14",
  },
  {
    quote: "testimonial.quote15",
    text: "testimonial.text15",
    logo: "../images/testimonial/bakeri.webp",
    location: "testimonial.location15",
    industry: "testimonial.industry15",
  },
];
export const achivementsData = [
  {
    text: "achivements.text1",
    btext: "achivements.btext1",
    img: "up_gov_w.svg",
  },
  {
    text: "achivements.text2",
    btext: "achivements.btext2",
    img: "gujyoga.svg",
  },
  {
    text: "achivements.text3",
    btext: "achivements.btext3",
    img: "ahimsa.svg",
  },
  {
    text: "achivements.text4",
    btext: "achivements.btext4",
    img: "dron.svg",
  },
  {
    text: "achivements.text5",
    btext: "achivements.btext5",
    img: "nursury.svg",
  },
  {
    text: "achivements.text6",
    btext: "achivements.btext6",
    img: "newIndia.svg",
  },
  {
    text: "achivements.text7",
    btext: "achivements.btext7",
    img: "newIndia.svg",
  },
];
export const BusinessVerticalsCards = [
  {
    title: "industries.title1",
    description: "industries.description1",
    image: "../images/business-verticals/Frame_1000002780.webp",
  },
  {
    title: "industries.title2",
    description: "industries.description2",
    image: "../images/business-verticals/Frame_1000002780-1.webp",
  },
  {
    title: "industries.title3",
    description: "industries.description3",
    image: "../images/business-verticals/Frame_1000002780-2.webp",
  },
  {
    title: "industries.title4",
    description: "industries.description4",
    image: "../images/business-verticals/Frame_1000002780-3.webp",
  },
  {
    title: "industries.title5",
    description: "industries.description5",
    image: "../images/business-verticals/Frame_1000002780-4.webp",
  },
  {
    title: "industries.title6",
    description: "industries.description6",
    image: "../images/business-verticals/Frame_1000002780-5.webp",
  },
  {
    title: "industries.title7",
    description: "industries.description7",
    image: "../images/business-verticals/Frame_1000002780-6.webp",
  },
  {
    title: "industries.title8",
    description: "industries.description8",
    image: "../images/business-verticals/Frame_1000002780-7.webp",
  },
];
export const BusinessVerticalsTabletCards = [
  {
    row_1_images: [
      {
        title: "industries.title1",
        description: "industries.description1",
        image: "../images/business-verticals/Frame_1000002780.webp",
      },
      {
        title: "industries.title2",
        description: "industries.description2",
        image: "../images/business-verticals/Frame_1000002780-1.webp",
      },
    ],
    row_2_images: [
      {
        title: "industries.title3",
        description: "industries.description3",
        image: "../images/business-verticals/Frame_1000002780-2.webp",
      },
      {
        title: "industries.title4",
        description: "industries.description4",
        image: "../images/business-verticals/Frame_1000002780-3.webp",
      },
    ],
  },
  {
    row_1_images: [
      {
        title: "industries.title5",
        description: "industries.description5",
        image: "../images/business-verticals/Frame_1000002780-4.webp",
      },
      {
        title: "industries.title6",
        description: "industries.description6",
        image: "../images/business-verticals/Frame_1000002780-5.webp",
      },
    ],
    row_2_images: [
      {
        title: "industries.title7",
        description: "industries.description7",
        image: "../images/business-verticals/Frame_1000002780-6.webp",
      },
      {
        title: "industries.title8",
        description: "industries.description8",
        image: "../images/business-verticals/Frame_1000002780-7.webp",
      },
    ],
  },
];

export const AreaExperticsData = [
  {
    id: 1,
    title: "areaexpertise.AI",
    cards: [
      {
        title: "areaexpertise.ai1",
        image: "../images/area-experties/AI/ai.webp",
      },
      {
        title: "areaexpertise.ai2",
        image: "../images/area-experties/AI/machine_learning.webp",
      },
      {
        title: "areaexpertise.ai3",
        image: "../images/area-experties/AI/data_science.webp",
      },
      {
        title: "areaexpertise.ai4",
        image: "../images/area-experties/AI/deep_learning.webp",
      },
      {
        title: "areaexpertise.ai5",
        image: "../images/area-experties/AI/natural_lang_pro.webp",
      },
      {
        title: "areaexpertise.ai6",
        image: "../images/area-experties/AI/comp_vision.webp",
      },
    ],
  },
  {
    id: 2,
    title: "areaexpertise.GenAI",
    cards: [
      {
        title: "areaexpertise.genai1",
        image: "../images/area-experties/GEN_AI/llm.webp",
      },
      {
        title: "areaexpertise.genai2",
        image: "../images/area-experties/GEN_AI/stable_diffusion.webp",
      },
      {
        title: "areaexpertise.genai3",
        image: "../images/area-experties/GEN_AI/langchain.webp",
      },
      {
        title: "areaexpertise.genai4",
        image: "../images/area-experties/GEN_AI/langgraph.webp",
      },
      {
        title: "areaexpertise.genai5",
        image: "../images/area-experties/GEN_AI/liamaindex.webp",
      },
      {
        title: "areaexpertise.genai6",
        image: "../images/area-experties/GEN_AI/agentic_rag.webp",
      },
    ],
  },
  {
    id: 3,
    title: "areaexpertise.Development",
    cards: [
      {
        title: "areaexpertise.dev1",
        image: "../images/area-experties/DEVELOPMENT/novel_algo.webp",
      },
      {
        title: "areaexpertise.dev2",
        image: "../images/area-experties/DEVELOPMENT/web_dev.webp",
      },
      {
        title: "areaexpertise.dev3",
        image: "../images/area-experties/DEVELOPMENT/backend_dev.webp",
      },
      {
        title: "areaexpertise.dev4",
        image: "../images/area-experties/DEVELOPMENT/custom_software_dev.webp",
      },
      {
        title: "areaexpertise.dev5",
        image: "../images/area-experties/DEVELOPMENT/cross_platform.webp",
      },
    ],
  },
  {
    id: 4,
    title: "areaexpertise.Deployment",
    cards: [
      {
        title: "areaexpertise.dep1",
        image: "../images/area-experties/DEPLOYMENT/kubernetes.webp",
      },
      {
        title: "areaexpertise.dep2",
        image: "../images/area-experties/DEPLOYMENT/argo_cd.webp",
      },
      {
        title: "areaexpertise.dep3",
        image: "../images/area-experties/DEPLOYMENT/docker.webp",
      },
      {
        title: "areaexpertise.dep4",
        image: "../images/area-experties/DEPLOYMENT/cicd.webp",
      },
      {
        title: "areaexpertise.dep5",
        image: "../images/area-experties/DEPLOYMENT/iaas.webp",
      },
      {
        title: "areaexpertise.dep6",
        image: "../images/area-experties/DEPLOYMENT/cloud_provider.webp",
      },
      
    ],
  },
];
