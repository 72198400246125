import React from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useTranslation } from "react-i18next";
import { getCountryByTimeZone } from "../../utils/getCountryByTimezone";
const WhatsappPhoneNumberForm = ({
  submitHandler,
  setValue,
  value,
  error,
  loading,
}) => {
  const { t } = useTranslation();
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userCountry = getCountryByTimeZone(userTimeZone);
  return (
    <form className="default-form d-flex flex-column" onSubmit={submitHandler}>
      <div className="form-group">
        <label
          style={{ marginBottom: "10px" }}
          htmlFor="whatsapp_phone_number_field"
          className="required"
        >
          {t("connectnowmodel.phonenumber")}
        </label>
        <PhoneInput
          value={value}
          defaultCountry={userCountry.toLowerCase()}
          onChange={(phone, country) => {
            setValue(phone);
          }}
          forceDialCode={true}
          inputProps={{
            style: {
              width: "100%",
              fontSize: "16px",
            },
          }}
        />

        <small style={{ color: "red" }}>{error && error}</small>
      </div>
      <button
        type="submit"
        className="btn whatsapp_phonenumber_submit_btn"
        disabled={value && value.length > 2 ? false : true}
      >
        {loading && (
          <i
            className="fa fa-spinner fa-spin mr-2 text-white"
            aria-hidden="true"
          />
        )}
        <span
          style={{
            fontSize: "16px",
            fontWeight: "600",
          }}
          className="text-white"
        >
          {t("connectnowmodel.submit")}
        </span>
      </button>
    </form>
  );
};

export default WhatsappPhoneNumberForm;
