import React from "react";
import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {DEFAULT_TABS} from "../../utils/constants";
import DeveloperRegistration from "../Auth/DeveloperRegistration/DeveloperRegistration";
import ClientRegistration from "../Auth/ClientRegistration/ClientRegistration";
import Login from "../Auth/Login/Login";
import UserProfile from "../Auth/UserProfile/UserProfile";
import LanguageSelector from "../../utils/LanguageSelector";
import { useTranslation } from "react-i18next";

const SideBar = () => {

    const {isLoggedIn, tabs} = useSelector(state => state.sessionHandler);
    const locator = useLocation();
    const {t}=useTranslation()

    return (
        <React.Fragment>
            <div className="side-menu">
                <div className="inner-wrapper">
                    
                    <span
                        className="btn-close link"
                        id="btn_sideNavClose"
                    />
                    <nav className="side-nav w-100">
                        <ul className="navbar-nav">
                            {!isLoggedIn && DEFAULT_TABS.map(tab => (
                                <React.Fragment key={"def_sidebar_tab" + tab.id}>
                                    {locator.pathname === tab.partialParentPath ?
                                        <React.Fragment>
                                            <li className="nav-item">
                                                <Link className="nav-link link scroll" to={tab.path}>
                                                    {tab.title}
                                                </Link>
                                                {tab.subTabs && <i className="fa fa-angle-down link" id={tab.icon_id}/>}
                                            </li>
                                            {tab.subTabs &&
                                            <div id={tab.div_id} className={tab.class}>
                                                {tab.subTabs.map((subTab, index) => (
                                                    <li className="nav-item" key={"def_sidebar_tab_child" + index}>
                                                        <Link className="nav-link link scroll" to={subTab.path}>
                                                            {subTab.title}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </div>
                                            }
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <li className="nav-item">
                                                <Link className="nav-link link" to={tab.partialParentPath + tab.path}
                                                      onClick={() => {
                                                          document.getElementById('btn_sideNavClose').click();
                                                      }}
                                                >
                                                    {tab.title}
                                                </Link>
                                                {tab.subTabs && <i className="fa fa-angle-down link" id={tab.icon_id}/>}
                                            </li>
                                            {
                                                tab.subTabs &&
                                                <div id={tab.div_id} className={tab.class}>
                                                    {
                                                        tab.subTabs.map((subTab, index) => (
                                                            <li className="nav-item" key={"def_sidebar_tab_child" + index}>
                                                                <Link className="nav-link link" to={subTab.partialParentPath + subTab.path}
                                                                      onClick={() => {
                                                                          document.getElementById('btn_sideNavClose').click();
                                                                      }}
                                                                >
                                                                    {subTab.title}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                </div>
                                            }
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            ))}
                            {isLoggedIn && tabs.map(tab => (
                                <React.Fragment key={'user_tabs_' + tab.id}>
                                    {locator.pathname.indexOf(tab.path) !== -1 ?
                                        <li className={'nav-item'}>
                                            <a className={"nav-link link scroll"} href={`#sec_${tab.index}`}
                                               onClick={() => document.getElementById('btn_sideNavClose').click()}
                                            >
                                                {tab.label}
                                            </a>
                                        </li>
                                        :
                                        <li key={`side_tab_${tab.id}`} className={'nav-item'}>
                                            <Link className={'nav-link link'} to={tab.path}>
                                                {tab.label}
                                            </Link>
                                        </li>
                                    }
                                </React.Fragment>
                            ))}

                            {!isLoggedIn ?
                                <React.Fragment>
                                    <DeveloperRegistration isSideBar={true}/>
                                    <ClientRegistration isSideBar={true}/>
                                    <Login isSideBar={true}/>
                                </React.Fragment>
                                :
                                <UserProfile isSideBar={true}/>
                            }
                            <div style={{width:"75px"}}>
                            <LanguageSelector navFlag={true} isSidebar={true}/>
                            </div>
                           
                        </ul>
                    </nav>

                    <div className="side-footer text-white w-100">
                        <ul className="social-icons-simple">
                            <li className="animated-wrap">
                                <div className="side-bar-social-link-wrapper">
                                <a
                                    className="animated-element"
                                    href="https://www.facebook.com/fx.data.labs/"
                                    target="_blank"
                                >
                                    <i className="fa fa-facebook"/>
                                </a>
                                </div>
                       
                            </li>
                            <li className="animated-wrap">
                            <div className="side-bar-social-link-wrapper">
                                <a
                                    className="animated-element"
                                    href="https://twitter.com/fxis_ai"
                                    target="_blank"
                                >
                                    <i className="fa fa-twitter"/>
                                </a>
                                </div>
                            </li>
                            <li className="animated-wrap">
                            <div className="side-bar-social-link-wrapper">
                                <a
                                    className="animated-element"
                                    href="https://www.linkedin.com/company/fxisai/mycompany"
                                    target="_blank"
                                >
                                    <i className="fa fa-linkedin"/>
                                </a>
                                </div>
                            </li>
                            <li className="animated-wrap">
                            <div className="side-bar-social-link-wrapper">
                                <a
                                    className="animated-element"
                                    href="https://www.instagram.com/fxis.ai/"
                                    target="_blank"
                                >
                                    <i className="fa fa-instagram"/>
                                </a>
                                </div>
                            </li>
                        </ul>
                        <p className="text-white">
                            &copy; {new Date().getFullYear()} {t("footer.rights")}
                        </p>
                    </div>
                </div>
            </div>
            <a href="#close" id="close_side_menu" />
        </React.Fragment>
    );
}

export default SideBar;
