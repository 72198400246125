import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";


import { increment, setTechData } from "../../redux/actions/sessionActions";

export default function Tech() {
    const [techInput, setTechInput] = useState([]);
    const [field, setfield] = useState("all");
    const [selectedTechnology, setSelectedTechnology] = useState([]);
    const [error, setError] = useState(null);
    const technolgies = useSelector(state => state.sessionHandler.technolgies);

    const options = [
        { value: "amazon-aws", label: "Amazon Aws" },
        { value: "google-cloud", label: "Google Cloud" },
        { value: "tensorflow", label: "Tensor Flow" },
        { value: "dash plotly", label: "Dash Plotly" },
        { value: "bootstrap", label: "Bootstrap" },
        { value: "nltk", label: "NLTK" },
        { value: "numpy", label: "Numpy" },
        { value: "java", label: "Java" },
        { value: "javascript", label: "JavaScript" },
        { value: "react", label: "React" },
        { value: "python", label: "Python" },
        { value: "angular", label: "Angular" },
        { value: "php", label: "PHP" },
        { value: "Laravel", label: "laravel" },
        { value: "vue", label: "Vue" },
        { value: "flask", label: "Flask" },
        { value: "selenium", label: "Selenium" },
        { value: "spark", label: "Spark" },
        { value: "adobexd", label: "Adobe Xd" },
        { value: "pytorch", label: "Pytorch" },
        { value: "opencv", label: "Open CV" },
        { value: "opennlp", label: "Open NLP" },
        { value: "pandas", label: "Pandas" },
        { value: "jquery", label: "Jquery" },
        { value: "keras", label: "Keras" },
        { value: "c", label: "C" },
        { value: "c++", label: "C++" },
        { value: "r", label: "R" },
        { value: "html", label: "Html" },
        { value: "git", label: "Git" },
        { value: "scilab", label: "Scilab" },
        { value: "matlab", label: "Matlab" },
        { value: "neutron", label: "Neutron" },
        { value: "redis", label: "Redis" },
        { value: "openstackswfit", label: "Open Stack Swift" },
        { value: "beanstalk", label: "Beanstalk" },
        { value: "virtualbox", label: "Virtual Box" }
    ];

    const animatedComponents = makeAnimated();
    const dispatch = useDispatch();
    const email = useSelector(state => state.sessionHandler.email);

    const handleSelectCard = name => {
        if (
            selectedTechnology !== undefined &&
            selectedTechnology.includes(name)
        ) {
            setSelectedTechnology(current =>
                current.filter(obj => {
                    return obj !== name;
                })
            );
        } else {
            setSelectedTechnology(() => {
                setError(null);
                return [...selectedTechnology, name];
            });
        }
    };


    let otherTech = techInput.map(item => item.value);

    const handleSubmit = e => {
        e.preventDefault();
        let technology =
            otherTech.length === 0
                ? selectedTechnology
                : [...selectedTechnology, ...otherTech];

        if (technolgies.length > 0) {
            technology = [...selectedTechnology, technolgies];
        }

        const data = { technology };


        if (technology.length <= 0 && technolgies.length <= 0) {
            setError("Please select at least one skill");
        } else {
            dispatch(setTechData(data));
            dispatch(increment());
        }
    };

    const handleKeyDown = event => {
        console.log(event.key)
        if (event.key === "Enter") {
            event.preventDefault();
            handleSubmit(event);
        }
    };

    const items = [
        {
            path: "java.svg",
            name: "java",
            text: "Java",
            tag: "fs",
        },
        {
            path: "python.svg",
            name: "python",
            text: "Python",
            tag: ["fs", "ds"]
        },
        {
            path: "js.svg",
            name: "javascript",
            text: "JavaScript",
            tag: "fs"
        },
        {
            path: "php.svg",
            name: "php",
            text: "PHP",
            tag: "fs"
        },
        {
            path: "react.svg",
            name: "react",
            text: "React",
            tag: "fs"
        },
        {
            path: "angular.svg",
            name: "angular",
            text: "Angular",
            tag: "fs"
        },
        {
            path: "django.svg",
            name: "django",
            text: "Django",
            tag: ["fs", "ds"]
        },
        {
            path: "lara.svg",
            name: "lara",
            text: "Laravel",
            tag: "fs"
        },
        // {
        //     path: "clang.svg",
        //     text: "C",
        //     tag: "fs"
        // },
        // {
        //     path: "c++.svg",
        //     text: "C++",
        //     tag: "fs"
        // },
        {
            path: "vue.svg",
            name: "vue",
            text: "Vue.js",
            tag: "fs"
        },
        // {
        //     path: "nodejs.svg",
        //     text: "Node.js",
        //     tag: "fs"
        // },
        {
            path: "flask.svg",
            name: "flask",
            text: "Flask",
            tag: ["fs", "ds"]
        },
        {
            path: "spark.svg",
            name: "spark",
            text: "Spark",
            tag: "ds"
        },
        {
            path: "selenium.svg",
            name: "selenium",
            text: "Selenium",
            tag: "ds"
        },
        // {
        //     path: "bootstrep.svg",
        //     text: "Bootstrap",
        //     tag: "fs"
        // },
        {
            path: "figma.svg",
            name: "figma",
            text: "Figma",
            tag: "design"
        },
        {
            path: "xd.svg",
            name: "xd",
            text: "Adobe Xd",
            tag: "design"
        }
    ];

    const removeItem = () => {
        // document.getElementById("mainLoader").style.display = "none";
    };

    removeItem();

    return (
        <React.Fragment>
            <div className="container-tech">
                <h3 className="tech-l1">Find developers to hire</h3>
                <div className="tech-div1">
                    <h6 className="tech-l2">Select at least one skill</h6>
                    <div className="arrow-down">
                        <select
                            className="tech-select"
                            name="All Skills"
                            id="skills"
                            defaultValue={"all"}
                            onChange={e => setfield(e.target.value)}
                        >
                            <div className="arrow"></div>
                            <option value="all">All Skills</option>

                            <option value="fs">Full Stack</option>
                            <option value="ds">Data Science</option>
                            <option value="design">Design</option>
                        </select>
                    </div>
                </div>
                <div className="tech-card-div">
                    {field !== "all"
                        ? items
                            .filter(obj => obj.tag.includes(field))
                            .map((item, index) => (
                                <div
                                    className={`tech-card ${selectedTechnology.includes(item.text)
                                        ? "card-border"
                                        : ""
                                        } ${technolgies.length > 0 &&
                                            technolgies.includes(item.text)
                                            ? "card-border"
                                            : ""
                                        }`}
                                    key={index}
                                    onClick={() =>
                                        handleSelectCard(item.text)
                                    }
                                >
                                    <div className={`sprite ${item.name}`} />
                                    <h6 className="tech-l3">{item.text}</h6>
                                </div>
                            ))
                        : items.map((item, index) => (
                            <div
                                className={`tech-card ${selectedTechnology.includes(item.text)
                                    ? "card-border"
                                    : ""
                                    } ${technolgies.length > 0 &&
                                        technolgies.includes(item.text)
                                        ? "card-border"
                                        : ""
                                    }`}
                                key={index}
                                onClick={() => handleSelectCard(item.text)}
                            >
                                <div className={`sprite ${item.name}`} />
                                <h6 className="tech-l3">{item.text}</h6>
                            </div>
                        ))}
                </div>

                <div className="tech-div2">
                    <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={options}
                        value={options.value}
                        components={animatedComponents}
                        onChange={setTechInput}
                        placeholder="Enter other skill(100+ available)"
                        centered
                        onKeyDown={handleKeyDown}
                    />
                    <div className="invalid-feedback d-block">{error}</div>

                    <label>Your email</label>
                    <input
                        className="tech-input-email"
                        type="text"
                        placeholder="Enter Email Your Here"
                        defaultValue={email}
                    ></input>
                    <button
                        className="btn btn-tech"
                        onClick={handleSubmit}
                        onKeyPress={handleKeyDown}
                    >
                        Continue
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
}
