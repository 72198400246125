import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import 'react-phone-number-input/style.css'
import WhatsappPhoneNumberForm from "./WhatsappPhoneNumberForm";
import axios from "axios";
import { useTranslation } from "react-i18next";
import EmailForm from "./EmailForm";
import { isPhoneValid } from "../../utils/phoneNumberValidation";

const ConnectNowModel = () => {
  const [showModal, setShowModal] = useState(false);
  const [isEmailForm,setEmailForm]=useState(false)
  const { t } = useTranslation();

  const [category, setCategory] = useState("collaborate");
  const [value, setValue] = useState();
  const [error, setError] = useState(null);
  const [status, setStatus] = useState({
    loading: false,
    success: false,
    error: null,
  });
 

  const sendWhatsappMessage = async () => {
    try {
      setStatus((prevStatus) => ({ ...prevStatus, loading: true }));
      await axios.post(`${process.env.REACT_APP_WHATSAPP_API_CALL}send`, {
        phone_number: value,
        category: category,
      });
      setStatus((prevStatus) => ({
        ...prevStatus,
        loading: false,
        success: true,
      }));
      setTimeout(() => {
        formReset();
      }, 2000);
    } catch (err) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        loading: false,
        error: "Something went wrong!",
      }));
    }
  };

 


  const submitHandler = (e) => {
    e.preventDefault();
    if (!value) {
      setError("Please provide your phone number");
    } else {
      const isValid = isPhoneValid(value);
      if (isValid) {
        sendWhatsappMessage(value);
      } else {
        setError("Please provide a valid phone number");
      }
    }
  };


  const formReset = () => {
    setShowModal(false);
    setValue();
    setError(null);
    setEmailForm(false)
    setStatus({
      loading: false,
      success: false,
      error: null,
    });
  };

  useEffect(() => {
    if (status.error) {
      setTimeout(() => {
        setStatus((prevStatus) => ({
          ...prevStatus,
          loading: false,
          error: null,
        }));
      }, 3000);
    }
  }, [status.error, setStatus]);

  return (
    <>
      <div className="landing-btn" style={{ display: "flex", alignItems: "center" }}>
      <button
        className="whatsapp_color_replace1 whatsapp_connect_now_btn "
        onClick={() => {
          setShowModal(true);
          setCategory("collaborate");
        }}
      >
       {t("home.connectnow1")}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="currentColor"
          className="bi bi-whatsapp ml-3"
          viewBox="0 0 16 16"
        >
          <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
        </svg>
      </button>            
      </div>    
      <p className="text-look text-white apply-now-text">
      {t("hirenow.lookingforjob")}{" "}
        <span
          className="text-white link-job"
          style={{cursor:"pointer"}}
          onClick={() => {
            setShowModal(true);
            setCategory("career");
          }}
        >
          {t("home.apply")}
        </span>
      </p>
  
      <Modal show={showModal} onHide={formReset} centered>
        <Modal.Header closeButton className="border-0">
        </Modal.Header>
        <Modal.Body style={{ padding: "0 30px 20px 30px" }}>
          <div className="w-100 d-flex flex-column align-items-center ">
            <div>
              <img src="/images/New Robot.webp" alt="" style={{width:"125px", height:"125px"}} />
            </div>
            <div className="w-100 mt-5 mb-4 d-flex flex-column justify-content-start align-items-start">
              <h5 style={{
                fontSize:"16px",
                color:"#1A1A1A"
              }} >We're here to lend a hand!</h5>
              <h2 style={{
                fontSize:"38px",
                fontWeight:"600",
                color:"#1A1A1A",
                letterSpacing:"-0.01em",
              }} className="mt-3">{(category==="collaborate")?"Write to us":"Join our team"}</h2>
            </div>
          </div>
          {status.success && (
            <div className={`alert alert-success`} style={{ padding: 10 }}>
              {t("connectnowmodel.wewillconnectsoon")}
            </div>
          )}
          {status.error !== null && (
            <div className={`alert alert-danger`} style={{ padding: 10 }}>
              {status.error}
            </div>
          )}
          {
            (isEmailForm)?
            <EmailForm
            setStatus={setStatus}
            formReset={formReset}
            loading={status.loading}
            message={(category==="collaborate")?"Write to us":"Join our team"}
            
            />
            :<WhatsappPhoneNumberForm
            submitHandler={submitHandler}
            value={value}
            error={error}
            loading={status.loading}
            setValue={setValue}
          />
          }
     
          <div className="w-100 mt-5 d-flex justify-content-center">
            <p style={{fontSize:"16px"}}>Continue with {(isEmailForm)?"mobile number ":"email "}  
            <button 
            className={`text-lowercase p-0`}
            disabled={status.loading?true:false}
            style={{
              fontSize:"16px", 
              color:"#5928d1",
              cursor:status.loading?"wait":"pointer",
              border:"none",
              background:"transparent",
              fontWeight:"600"
            }}
            onClick={()=>{setEmailForm(!isEmailForm);
              setValue();
              setError(null);
              setStatus({
                loading: false,
                success: false,
                error: null,
              });}}
            > click here</button></p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConnectNowModel;
