import React from "react";
import scriptLoader from "../../utils/javascriptsLoader";
import { useTranslation } from "react-i18next";

class Products extends React.Component {
    loadUpdateLazy = () => {
        if (!document.getElementById("updateLazyId")) {
            const script = {
                src:
                    process.env.REACT_APP_BASE_URL +
                    "js/updatelazy.min.js?ca93809a-cd54-40d0-ad2c-a763aba41894",
                id: "updateLazyId",
                async: 1,
                defer: 1
            };
            scriptLoader(script);
        }
    };
    componentDidMount() {
        this.props.mounted();
        this.loadUpdateLazy();
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <section id="products" style={{padding:'6rem 0 3rem 0'}}>
                    <div className="container">
                        <div className="row wow fadeIn">
                            <div className="col-md-12 text-center">
                                <div className="title d-inline-block">
                                    <h2 className="gradient-text-default">
                                        {t("products.title")}
                                    </h2>
                                </div>
                            </div>
                        </div>

                        <div
                            className="row align-items-center"
                            id="p_haveto"
                        >
                            <div className="col-md-6 wow bounceInLeft">
                                <div className="blog-text">
                                    <h2>{t("products.product1")}</h2>
                                    <p className="p-sm p-justify m-3">
                                    {t("products.desc1")}
                                    </p>
                                    <a
                                        href="https://haveto.com/"
                                        target="_blank"
                                        className="btn btn-large btn-gradient btn-rounded mt-4"
                                        rel="noreferrer"
                                    >
                                       {t("products.exploremore")}
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="blog-image text-right wow hover-effect bounceInRight">
                                    <img
                                        data-src="../images/Haveto_Logo.webp"
                                        className="lazy"
                                        width="100%"
                                        height="auto"
                                        alt="HaveTo"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            className="row align-items-center"
                            id="p_entropy"
                        >
                            <div className="col-md-6 order-md-2 wow bounceInRight">
                                <div className="blog-text">
                                    <h2>{t("products.product2")}</h2>
                                    <p className="p-sm p-justify m-3">
                                    {t("products.desc2")}
                                    </p>
                                    <a
                                        href="https://makertaker.com/"
                                        target="_blank"
                                        className="btn btn-large btn-gradient btn-rounded mt-4"
                                        rel="noreferrer"
                                    >
                                         {t("products.exploremore")}
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="blog-image wow hover-effect bounceInLeft">
                                    <img
                                        data-src="../images/MakerTaker.svg"
                                        className="lazy"
                                        width="100%"
                                        height="auto"
                                        alt="Entropy"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            className="row align-items-center"
                            id="p_hplus_tree"
                        >
                            <div className="col-md-6 wow bounceInLeft">
                                <div className="blog-text">
                                    <h2>{t("products.product3")}</h2>
                                    <p className="p-sm p-justify m-3">
                                    {t("products.desc3")}
                                    </p>
                                    <a
                                        id="htreeplusVID"
                                        data-fancybox=""
                                        href="https://www.youtube.com/watch?v=ix2MFMEGkIg"
                                        className="btn btn-large btn-gradient btn-rounded mt-4"
                                    >
                                        {t("products.exploremore")}
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="blog-image text-right wow hover-effect bounceInRight">
                                    <img
                                        data-src="../images/h+tree1.svg"
                                        className="lazy"
                                        width="100%"
                                        height="auto"
                                        alt="H+Tree"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            className="row align-items-center"
                            id="p_fx_optimizer"
                        >
                            <div className="col-md-6 order-md-2 wow bounceInRight">
                                <div className="blog-text">
                                    <h2>{t("products.product4")}</h2>
                                    <p className="p-sm p-justify m-3">
                                    {t("products.desc4")}
                                    </p>
                                    <a
                                        href="https://pdfs.semanticscholar.org/2ce7/b7d2599652a5b57f919a17977a5323a5c78b.pdf"
                                        target="_blank"
                                        className="btn btn-large btn-gradient btn-rounded mt-4"
                                        rel="noreferrer"
                                    >
                                         {t("products.exploremore")}
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="blog-image hover-effect wow bounceInLeft">
                                    <img
                                        data-src="../images/fx_optimizer1.svg"
                                        className="lazy"
                                        width="100%"
                                        height="auto"
                                        alt="F(x) Optimizer"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center" id="p_fx_erp">
                            <div className="col-md-6 wow bounceInLeft">
                                <div className="blog-text">
                                    <h2>{t("products.product5")}</h2>
                                    <p className="p-sm p-justify m-3">
                                    {t("products.desc5")}
                                    </p>
                                    <a
                                        href="https://www.bakeri.com/"
                                        target="_blank"
                                        className="btn btn-large btn-gradient btn-rounded mt-4"
                                        rel="noreferrer"
                                    >
                                       {t("products.exploremore")}
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="blog-image text-right hover-effect wow bounceInRight">
                                    <img
                                        data-src="../images/fx_ERP.svg"
                                        className="lazy"
                                        width="100%"
                                        height="auto"
                                        alt="F(x) ERP"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* <div className="row align-items-center" id='p_fx_data_clouds'>
                            <div className="col-md-6 wow bounceInLeft">
                                <div className="blog-text">
                                    <h2>F(x) Data Cloud</h2>
                                    <p className="p-sm p-justify">F(x) Data cloud is Asia's first company powered by OpenStack to provide Infrastructure as a Service,
                                        Database as a Service, Backup as a Service, Network as a Service, Block as a Service, and Object as a
                                        Service. All the services can be scaled easily, having a high Uptime guarantee. These packages come with
                                        a throng of features, including Custom SSD Storage, Resize Instance, Shelve Instance, Pause Instance,
                                        Suspend Instance, Lock Instance, Soft/Hard Reboot, and much more. It also has the widest range of
                                        databases to choose from!
                                    </p>
                                    <button className="btn btn-large btn-gradient btn-rounded mt-4" onClick={() => window.$crisp.push(['do', 'chat:open'])}>Learn More
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="blog-image text-right hover-effect wow bounceInRight">
                                    <img data-src="../images/fx_cloud.webp" className="lazy" alt="F(x) Cloud"/>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const WrappedProducts = (props) => {
    const { t } = useTranslation();
    return <Products {...props} t={t} />;
  };
  
  export default WrappedProducts;
