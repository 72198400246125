import React from "react";
import Testimonial from "./TestimonySection/Testimonial";
import Services from "./ServiceSection/Services";
import Products from "./Products";
import Info from "./Info";
import Footer from "./FooterSection/Footer";
import HireExExperts from "./HireExExpertSection/HireExExperts";
import Awards from "./AwardSection/Awards";
import Clientele from "./AboutSection/Clientele";
import GwrSection from "./GwrSection";

class OnDemandLoader extends React.Component {
  render() {
    const { addToList } = this.props;
    return (
      <React.Suspense fallback={""}>
        <Clientele mounted={addToList} />
        <HireExExperts mounted={addToList} />
        <Testimonial mounted={addToList} />
        <Services mounted={addToList} />
        <Awards mounted={addToList} />
        <GwrSection mounted={addToList} />
        <Products mounted={addToList} />
        <Info mounted={addToList} />
        <Footer mounted={addToList} />
      </React.Suspense>
    );
  }
}

export default OnDemandLoader;
