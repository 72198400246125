export const TwitterIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4277_19975)">
      <path
        d="M0.492147 0.105469C0.527303 0.158203 4.3857 5.7832 9.07027 12.5947C13.7461 19.4062 17.5781 24.9961 17.5781 25.0137C17.5781 25.0312 1.76656 43.4443 0.544881 44.8418C0.421834 44.9912 0.518514 45 2.42574 45H4.44722L11.9179 36.3076L19.3974 27.624L25.3652 36.3076L31.333 45H37.9511C43.2246 45 44.5605 44.9736 44.499 44.8857C44.4638 44.833 40.4912 39.0322 35.666 32.0098C30.832 24.9873 26.8681 19.1953 26.8418 19.1338C26.8066 19.0283 28.9423 16.5234 40.2275 3.40137L43.163 0H41.1416H39.1201L32.0625 8.20898C28.1777 12.7178 24.9785 16.4004 24.9521 16.3828C24.9257 16.374 22.3769 12.6738 19.2832 8.18262L13.667 0H7.04879C1.94234 0 0.448201 0.0263672 0.492147 0.105469ZM25.0927 22.1221C32.4404 32.6426 38.6015 41.458 38.7861 41.7217L39.1201 42.1875H36.1933L33.2666 42.1787L19.9072 23.0625C12.5595 12.542 6.3984 3.72656 6.21383 3.46289L5.87105 2.98828H8.8066L11.7334 2.99707L25.0927 22.1221Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_4277_19975">
        <rect width="45" height="45" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
