import React, { useState } from "react";
import Calendar from "react-calendar";
import scriptLoader from "../../utils/javascriptsLoader";
import TimezonePicker from "react-timezone";
import { useDispatch } from "react-redux";
import "react-calendar/dist/Calendar.css";
import { hire_now_register } from "../../redux/actions/sessionActions";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

function Calender() {
    const [selectedTimezone, setSelectedTimezone] = useState("Asia/Kolkata");


    const [time, setTime] = useState(moment.tz('').toDate());

    const dispatch = useDispatch();

    let meetTime = 0;



    const email = useSelector(state => state.sessionHandler.email);
    const phone = useSelector(state => state.sessionHandler.phone);
    const employeesValue = useSelector(
        state => state.sessionHandler.employeesValue
    );
    const ipoValue = useSelector(state => state.sessionHandler.ipoValue);
    const ventureValue = useSelector(state => state.sessionHandler.venueValue);
    const timeValue = useSelector(state => state.sessionHandler.timeValue);
    const webSiteLink = useSelector(state => state.sessionHandler.webSiteLink);
    const technolgies = useSelector(state => state.sessionHandler.technolgies);
    const field = useSelector(state => state.sessionHandler.field);

    const data = {
        email,
        phone,
        employeesValue,
        ipoValue,
        ventureValue,
        timeValue,
        webSiteLink,
        technolgies,
        field
    };

    const handleSubmit = () => {
        dispatch(hire_now_register(data));
    };

    const handleMeetTime = e => {
        let x = document.getElementById("confirm");
        x.style.opacity = 1;
        meetTime = e.target.value;
    };

    const loadScript = () => {
        const scriptParam = {
            id: "usageScriptId",
            src: process.env.PUBLIC_URL + "/js/modules/cale.js",
            async: 1,
            defer: 1
        };
        scriptLoader(scriptParam);
    };

    loadScript();

    return (
        <React.Fragment>
            <div className="container-cal">
                <div className="cal-div1">
                    <h4 className="cal-l1">Hiring call</h4>
                    <div className="cal-sub-div">
                        <img
                            src="../images/time.svg"
                            alt="calender"
                            className="cal-img"
                        />
                        <h5 className="cal-l2">30 min</h5>
                    </div>
                    <p className="cal-l3">
                        Book call with us so we better understand your
                        <br />
                        job details. After the call:
                    </p>
                    <ul className="list-group">
                        <li>We send you engineer resumes.</li>
                        <li>You Interview engineers.</li>
                        <li>Start 2 week no-risk trial.</li>
                    </ul>
                </div>
                <div className="cal-div2" id="date-time">
                    <div className="cal-sub-div1">
                        <h3 className="cal-l4">Select a Date & time</h3>
                        <div className="cal-timezone">
                            <img
                                src="../images/world.svg"
                                className="world"
                                alt="calender"
                            />

                            <TimezonePicker
                                value={selectedTimezone}
                                onChange={timezone =>
                                    setSelectedTimezone(timezone)
                                }
                                inputProps={{
                                    placeholder: "Select Timezone...",
                                    name: "timezone"
                                }}
                            />
                            <img
                                src="../images/polygon.svg"
                                className="polygon"
                                alt="calender"
                            />
                        </div>

                        <Calendar onChange={setTime} value={time} />
                        <button className="btn-cal1" id="continue" disabled>
                            Continue
                        </button>
                    </div>

                    <div className="cal-sub-div2" id="time-slote">
                        <h3 className="cal-l5">
                            {time.toLocaleString("default", {
                                weekday: "long"
                            }) +
                                "," +
                                time.toLocaleString("default", {
                                    month: "long"
                                }) +
                                " " +
                                time.getDate()}
                        </h3>
                        <div className="cal-sub-div3">
                            <button
                                className="btn-cal"
                                id="btn1"
                                value="7:00"
                                onClick={handleMeetTime}
                            >
                                7:30
                            </button>
                            <button
                                className="btn-cal"
                                id="btn2"
                                value="8:30"
                                onClick={handleMeetTime}
                            >
                                8:30
                            </button>
                            <button
                                className="btn-cal"
                                id="btn3"
                                value="9:00"
                                onClick={handleMeetTime}
                            >
                                9:00
                            </button>
                            <button
                                className="btn-cal"
                                id="btn4"
                                value="11:00"
                                onClick={handleMeetTime}
                            >
                                11:00
                            </button>
                            <button
                                className="btn-cal"
                                id="btn5"
                                value="12:30"
                                onClick={handleMeetTime}
                            >
                                12:30
                            </button>
                            <button
                                className="btn-cal"
                                id="btn6"
                                value="1:30"
                                onClick={handleMeetTime}
                            >
                                1:30
                            </button>
                            <button
                                className="btn-cal"
                                id="btn7"
                                value="3:00"
                                onClick={handleMeetTime}
                            >
                                3:00
                            </button>
                            <button
                                className="btn-cal"
                                id="btn8"
                                value="4:00"
                                onClick={handleMeetTime}
                            >
                                4:00
                            </button>
                        </div>
                        <button
                            className="btn-cal2 "
                            id="confirm"
                            onClick={handleSubmit}
                            // disabled={true}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Calender;
